import Button, { ButtonProps } from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded'
import { useState } from 'react'

type ViewTranscriptButtonProps = {
  transcript?: string
} & ButtonProps

const ViewTranscriptButton = ({ transcript, ...props }: ViewTranscriptButtonProps) => {
  const [open, setOpen] = useState(false)

  if (!transcript && transcript !== '_exists') {
    return null
  }

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)} color="primary" endIcon={<OpenInNewIcon />}>
        View Transcript
      </Button>
      <ClosableDialog open={open} onClose={() => setOpen(false)} titleText="Transcript">
        <DialogContent>
          <Typography className="ph-no-capture">
            {transcript.split('\n').map((t, i) => (
              <p key={i}>{t}</p>
            ))}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Button onClick={() => setOpen(false)}>Close</Button>
          </Grid>
        </DialogActions>
      </ClosableDialog>
    </>
  )
}

export default ViewTranscriptButton
