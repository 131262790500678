import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import { url } from '../util/api/url'
import checkToken from '../util/auth/checkToken'
import useRequireAuth from '../util/auth/useRequireAuth'
import useUser from '../util/auth/useUser'
import handleError from '../util/handleError'
import log from '../util/logging'

const BAA = () => {
  useTitle('Clinical Notes AI - BAA')
  useRequireAuth()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const baaDone = searchParams.get('baa') === 'true'

  const [user, setUser] = useUser()

  const handleSetBAADownloaded = () => {
    Cookies.set('dl-baa', 'true', { expires: 365 })
  }

  useEffect(() => {
    if (baaDone) {
      toast.success('BAA Submitted')
      handleSetBAADownloaded()
    }
  }, [baaDone])

  const populateUser = useCallback(async () => {
    try {
      await checkToken()

      const data = await authRequest.user.account.get({ token: '' })
      setUser(data.user)
    } catch (err) {
      handleError(err)
    }
  }, [setUser])

  useEffect(() => {
    populateUser()
  }, [populateUser])

  const handleOpenBAA = async (e: React.MouseEvent) => {
    try {
      e.preventDefault()
      if (!user) return

      toast.success('BAA Downloading...')
      const response = await fetch(url + '/mailing/baa', {
        method: 'POST',
        body: JSON.stringify({ email: user.email }),
        headers: {
          //File response
          'Content-Type': 'application/pdf',
        },
      })

      const blob = await response.blob()

      // Download the file
      const u = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = u
      a.download = 'Clinical Notes AI Business Associate Agreement Signed.pdf'
      a.click()
    } catch (err) {
      log.error(err)
    }
  }

  const handleBAANext = () => {
    handleSetBAADownloaded()
    navigate('/')
  }

  return (
    <PageContainer>
      <Paper elevation={0}>
        <Grid container p={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item textAlign="center" xs={12}>
                <Typography textAlign="center" variant="h5" sx={{ mb: 1 }}>
                  Business Associate Agreement
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Feel free to download our BAA and submit a signed copy via the button below.
                </Typography>
                <Button sx={{ mt: 2 }} variant="contained" onClick={handleOpenBAA}>
                  Download BAA
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ mt: 2 }}
                  href="https://share.hsforms.com/1lDe9NIgPTcmQPQzD2HjAMAqkiqd"
                >
                  Submit BAA
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}
              >
                <Button variant="contained" onClick={handleBAANext}>
                  Skip
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                  textAlign="center"
                >
                  Press Skip if you have already submitted your BAA, or your organization already
                  has a BAA on file.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </PageContainer>
  )
}

export default BAA
