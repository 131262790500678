import {
  ContextItem,
  PatientSessionsListPatientOptions,
} from '@counsel-project/counsel-transcribe-api'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Pagination from '@mui/material/Pagination'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { ehrRequest } from '../../util/api/ehr-api'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import ClosableDialog from '../ClosableDialog'
import { contextObjectTypeToType, convertSessionToContextItem } from './_helpers'
import ContextListItem from './ContextListItem'

type ContextDialogProps = {
  open: boolean
  onClose: () => void
  patientId?: string
  integrationPatientId?: string
  value: ContextItem[]
  onChange: (value: ContextItem[]) => void
}

const ContextDialog = ({
  open,
  onClose,
  patientId,
  integrationPatientId,
  value,
  onChange,
}: ContextDialogProps) => {
  const [options, setOptions] = useState<ContextItem[]>([])
  const [ehrOptions, setEhrOptions] = useState<ContextItem[]>([])
  const [page, setPage] = useState(0)
  const [tab, setTab] = useState<'treatment-plans' | 'sessions' | 'notes' | 'ehr'>(
    integrationPatientId ? 'ehr' : 'treatment-plans'
  )
  const [ehrLoading, setEhrLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  // Auto set tab based on ehrPatientId
  useEffect(() => {
    if (!integrationPatientId) {
      setTab('treatment-plans')
      return
    }

    setTab('ehr')
  }, [integrationPatientId])

  const populateEhrOptions = useCallback(async () => {
    try {
      if (!integrationPatientId) return

      setEhrLoading(true)

      await checkToken()

      const { context } = await ehrRequest.integrations.patients.getContext({
        token: '',
        patientId: integrationPatientId,
        types: ['treatment', 'assessment', 'progress', 'intake'],
        limit: 20,
      })

      setEhrOptions(
        context.map((c, i) => ({
          id: 'ehr-' + i,
          title: c.title,
          content: c.content,
          date: c.date,
          type: contextObjectTypeToType(c.type),
          ehr: true,
        }))
      )
    } catch (err) {
      handleError(err)
    } finally {
      setEhrLoading(false)
    }
  }, [integrationPatientId])

  useEffect(() => {
    if (!open) return

    const timeout = setTimeout(() => {
      populateEhrOptions()
    }, 10)

    return () => clearTimeout(timeout)
  }, [open, populateEhrOptions])

  const populateOptions = useCallback(async () => {
    try {
      if (!patientId) return
      if (tab === 'ehr') return

      setLoading(true)

      let query: PatientSessionsListPatientOptions['search'] = {}

      if (tab === 'sessions') {
        query.and = [
          {
            $not: true,
            type: 'note',
          },
          {
            $not: true,
            type: 'document',
          },
          {
            $not: true,
            type: 'custom',
          },
        ]
      } else if (tab === 'notes') {
        query.or = [
          {
            type: 'note',
          },
          {
            type: 'document',
          },
          {
            type: 'custom',
          },
        ]
      } else if (tab === 'treatment-plans') {
        query.and = [
          {
            documentType: 'Treatment Plan',
          },
        ]
      }

      await checkToken()

      const { results, total } = await transcribeRequest.sessions.list.patient({
        token: '',
        patientId,
        limit: 20,
        offset: page * 20,
        search: query,
        sort: 'createdAt',
        direction: 'desc',
      })

      setTotal(total)

      setOptions(results.map(convertSessionToContextItem))
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [patientId, tab, page])

  useEffect(() => {
    if (!open) return

    const timeout = setTimeout(() => {
      populateOptions()
    }, 10)

    return () => clearTimeout(timeout)
  }, [open, populateOptions])

  const handleCheck = (item: ContextItem, checked: boolean) => {
    if (checked) {
      if (value.length > 9) {
        toast.error('You can only select up to 10 items', { id: 'context-dialog-error' })
        return
      }
      onChange([...value, item])
    } else {
      onChange(value.filter((v) => v.id !== item.id))
    }
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Chart">
      <DialogTitle>
        <Typography variant="body1" fontSize={16} sx={{ mb: 2 }}>
          Choose which part of the chart you would like the AI to reference before building your
          document.
        </Typography>
        <Grid container spacing={1}>
          {integrationPatientId && (
            <Grid item xs>
              <Button
                size="small"
                fullWidth
                variant={tab === 'ehr' ? 'contained' : 'text'}
                onClick={() => setTab('ehr')}
              >
                From EHR
              </Button>
            </Grid>
          )}
          {patientId && (
            <>
              <Grid item xs>
                <Button
                  size="small"
                  fullWidth
                  variant={tab === 'treatment-plans' ? 'contained' : 'text'}
                  onClick={() => setTab('treatment-plans')}
                >
                  Treatment Plans
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  size="small"
                  fullWidth
                  variant={tab === 'notes' ? 'contained' : 'text'}
                  onClick={() => setTab('notes')}
                >
                  Notes
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  size="small"
                  fullWidth
                  variant={tab === 'sessions' ? 'contained' : 'text'}
                  onClick={() => setTab('sessions')}
                >
                  Sessions
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ height: '500px' }}>
        {tab !== 'ehr' ? (
          <Grid container spacing={1} sx={{ mt: 2 }}>
            {!loading &&
              options.map((o) => (
                <Grid item xs={12} key={o.id}>
                  <ContextListItem
                    item={o}
                    checked={value.some((v) => v.id === o.id)}
                    onChange={(checked) => handleCheck(o, checked)}
                  />
                </Grid>
              ))}
            {!loading && options.length === 0 ? (
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center">
                  No results found
                </Typography>
              </Grid>
            ) : loading ? (
              <>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
              </>
            ) : null}
          </Grid>
        ) : (
          <Grid container spacing={1} sx={{ mt: 2 }}>
            {!ehrLoading &&
              ehrOptions.map((o) => (
                <Grid item xs={12} key={o.id}>
                  <ContextListItem
                    item={o}
                    checked={value.some((v) => v.id === o.id)}
                    onChange={(checked) => handleCheck(o, checked)}
                  />
                </Grid>
              ))}
            {!ehrLoading && ehrOptions.length === 0 ? (
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center">
                  No results found
                </Typography>
              </Grid>
            ) : ehrLoading ? (
              <>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={48} />
                </Grid>
              </>
            ) : null}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {(tab !== 'ehr' && loading) || (tab === 'ehr' && ehrLoading) ? (
          <CircularProgress size={24} />
        ) : (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <IconButton onClick={() => onChange([])} size="small" disabled={value.length === 0}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{ width: '120px' }}>
                {value.length} selected
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="end" spacing={1}>
          {tab !== 'ehr' && (
            <Grid item>
              <Pagination
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={Math.ceil(total / 20)}
                onChange={(_, page) => setPage(page - 1)}
              />
            </Grid>
          )}
          <Grid item>
            <Button variant="contained" onClick={() => onClose()}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ContextDialog
