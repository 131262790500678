import { RTranscriptLog } from '@counsel-project/counsel-transcribe-api'
import BackIcon from '@mui/icons-material/ArrowBack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TranscriptLogContent from './TranscriptLogContent'

type TranscriptLogDialogProps = {
  open: boolean
  onClose: () => void
  transcript: Omit<RTranscriptLog, 'transcript'>
}

const TranscriptLogDialog = ({ open, onClose, transcript }: TranscriptLogDialogProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BackIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="ph-no-capture">
          <TranscriptLogContent transcript={transcript} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TranscriptLogDialog
