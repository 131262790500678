import { ArticleItem } from '../types'
import md from './index.md'
import DesktopIcon from '@mui/icons-material/DesktopWindowsRounded'

const article: ArticleItem = {
  title: 'Web - Template Modification',
  description:
    'Learn how to use Clinical Notes AI to easily edit notes & documents from your sessions.',
  path: 'template-modification',
  keywords: ['template modification'],
  content: md,
  videoTitle: 'How to use Template Modification',
  video:
    'https://www.loom.com/embed/ce2df0f4b934472eacd3d23b78b2c896?sid=a9bd5af1-5caf-492e-8a50-b654a71a7723',
  linkedArticles: ['getting-started-extension'],
  icon: DesktopIcon,
}

export default article
