import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Using PracticeQ',
  description: 'Learn how to use the Clinical Notes AI Extension with PracticeQ.',
  path: 'ext-practiceq',
  keywords: ['getting started', 'practiceq'],
  content: md,
  videoTitle: 'How to use Clinical Notes AI with PracticeQ',
  video:
    'https://www.loom.com/embed/e915675be57f4878ae77f6fb804f5491?sid=98122eca-5ed6-4120-9548-f429f4ea39de',
  linkedArticles: ['ext-template-modification'],
  icon: ExtensionIcon,
}

export default article
