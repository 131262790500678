import { RLayout } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumbs, { BreadcrumbPath } from '../../../components/Breadcrumbs'
import PageContainer from '../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import useUser from '../../../util/auth/useUser'
import handleError from '../../../util/handleError'
import FormLayoutControls, { FormLayoutSaveOptions } from '../FormLayoutControls'
import LayoutHeader from '../LayoutHeader'
import {
  ALL_TEMPLATE_OPTIONS,
  TemplateEditor,
  TemplateOptions,
  TemplateShowOptions,
  PopulateExamplesOptions,
} from '@counsel-project/components'
import { InputField, OutputField } from '@counsel-project/counsel-generation-api'
import getUser from '../../../util/auth/getUser'
import { wait } from '@counsel-project/client-utils'

const LayoutFormPage = () => {
  const { layoutId } = useParams()

  const [layout, setLayout] = useState<RLayout | null>(null)
  const [saving, setSaving] = useState(false)
  const [injectedFields, setInjectedFields] = useState<{ id: string; instructions: string }[]>([])

  const [user] = useUser()

  const navigate = useNavigate()

  const template: TemplateOptions = useMemo(() => {
    return {
      name: layout?.name || '',
      fields: layout?.fields || [],
      identifier: layout?.identifier || '',
      instructions: layout?.documentInfo || '',
      examples: layout?.fieldExamples || [],
    }
  }, [layout])

  const populateInjectedFields = useCallback(async () => {
    if (!layout || !layout.fields) return

    const user = getUser()

    const { results } = await transcribeRequest.prompts.inject({
      token: '',
      profession: user?.profession || '',
      templateName: layout.name || '',
      fields: layout.fields.map((f) => ({ ...f, instructions: '' })),
    })

    setInjectedFields(results?.map((f) => ({ id: f.id, instructions: f.instructions || '' })) || [])
  }, [layout])

  useEffect(() => {
    const timeout = setTimeout(populateInjectedFields, 10)
    return () => clearTimeout(timeout)
  }, [populateInjectedFields])

  const handlePopulateLayout = useCallback(async () => {
    try {
      if (!layoutId) return

      await checkToken()

      const { result } = await transcribeRequest.layouts.get({
        layoutId,
        token: '',
      })

      if (!result.fields) {
        return navigate(`/layouts/view/${layoutId}`)
      }

      setLayout(result)
    } catch (err) {
      handleError(err)
    }
  }, [layoutId, navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handlePopulateLayout()
    }, 10)

    return () => clearTimeout(timeout)
  }, [handlePopulateLayout])

  const handleChange = useCallback((template: { fields: InputField[]; instructions: string }) => {
    setLayout((prev) =>
      prev
        ? {
            ...prev,
            fields: template.fields || [],
            documentInfo: template.instructions || '',
          }
        : null
    )
  }, [])

  const handleSave = useCallback(
    async (saved: TemplateOptions) => {
      try {
        if (!layoutId) return

        setSaving(true)

        await checkToken()

        const { result } = await transcribeRequest.layouts.update({
          layoutId,
          token: '',
          fieldExamples: saved.examples,
          fields: saved.fields,
          documentInfo: saved.instructions,
          directoryId: layout?.directoryId || '',
        })

        setLayout(result)

        setSaving(false)

        toast.success('Template saved')
      } catch (err) {
        handleError(err)
        setSaving(false)
      }
    },
    [layoutId, layout]
  )

  const handleControlsSave = useCallback(
    async (options: FormLayoutSaveOptions) => {
      try {
        if (!layoutId) return

        setSaving(true)

        await checkToken()

        const { result } = await transcribeRequest.layouts.update({
          layoutId,
          token: '',
          fields: layout?.fields || [],
          documentInfo: layout?.documentInfo || '',
          ...options,
        })

        setLayout(result)

        setSaving(false)

        toast.success('Template saved')
      } catch (err) {
        handleError(err)
        setSaving(false)
      }
    },
    [layoutId, layout]
  )

  const handleUpdateExamples = useCallback(
    async (examples: OutputField[]) => {
      try {
        if (examples.length === 0) return

        if (!layoutId) return

        await checkToken()

        transcribeRequest.layouts.update({
          layoutId,
          token: '',
          fieldExamples: examples,
        })
      } catch (err) {
        handleError(err)
      }
    },
    [layoutId]
  )

  const breadcrumbs: BreadcrumbPath[] = [
    {
      name: 'Layouts',
      path: '/layouts',
    },
    {
      name: 'Template',
    },
  ]

  const isEditor =
    layout?.editors?.includes(user?.email || '') || user?.admin || user?._id === layout?.userId

  const properties: TemplateShowOptions[] = useMemo(() => {
    if (layout?.source === 'user' && user?.admin) {
      return ALL_TEMPLATE_OPTIONS.filter((o) => o !== 'save') as TemplateShowOptions[]
    }

    if (layout?.source === 'user') {
      return [
        'examples',
        'format',
        'instructions',
        'temperature',
        'omit-names',
        'add',
        'remove',
        'id',
        'options',
        'reorder',
        'name',
        'disabled',
      ]
    }

    if (user?.admin) {
      return [
        'examples',
        'format',
        'instructions',
        'temperature',
        'omit-names',
        'disabled',
        'models',
      ]
    }

    return ['examples', 'format', 'instructions', 'temperature', 'omit-names', 'disabled']
  }, [layout, user])

  const handlePopulateExample = useCallback(
    async (options: PopulateExamplesOptions) => {
      const { outputs, transcript, context, template } = options

      if (!layoutId) throw new Error('No layoutId')

      await checkToken()

      const { result } = await transcribeRequest.layouts.generateExampleField({
        layoutId,
        token: '',
        outputs,
        transcript,
        context,
        fields: template?.fields || [],
        instructions: template?.instructions || '',
      })

      return result
    },
    [layoutId]
  )

  const handleOnGeneratedFields = useCallback(
    async (fields: InputField[]) => {
      try {
        if (!layoutId) return
        if (!layout) return

        const newLayout = layout

        setLayout(null)

        await wait(100)

        setLayout({
          ...newLayout,
          fields: fields,
        })
      } catch (err) {
        handleError(err)
      }
    },
    [layoutId, layout]
  )

  if (!layoutId || !layout) {
    return (
      <Box>
        <PageContainer>
          <Breadcrumbs paths={breadcrumbs} />
        </PageContainer>
        <Container sx={{ p: { xs: 1, sm: 2 } }}>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1} alignItems="start">
                <Grid item xs>
                  <Skeleton
                    variant="text"
                    height={80}
                    sx={{ backgroundColor: 'background.paper' }}
                  />
                  <Skeleton
                    variant="text"
                    height={30}
                    sx={{ backgroundColor: 'background.paper' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={240} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }

  return (
    <Box>
      <PageContainer>
        <LayoutHeader layout={layout} breadcrumbs={breadcrumbs} onChangeLayout={setLayout} />
      </PageContainer>
      <TemplateEditor
        properties={properties}
        template={template}
        onSave={handleSave}
        onChange={handleChange}
        disabled={saving}
        loading={saving}
        handlePopulateExample={handlePopulateExample}
        onError={handleError}
        defaultInstructions={injectedFields}
      />

      <PageContainer>
        <Grid item xs={12}>
          <FormLayoutControls
            layout={layout}
            loading={saving}
            onSave={handleControlsSave}
            disabled={!isEditor || saving}
            onAutoGenerateInstructions={handleOnGeneratedFields}
          />
        </Grid>
      </PageContainer>
    </Box>
  )
}

export default LayoutFormPage
