import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Template Modification',
  description:
    'Learn how to use the Clinical Notes AI Extension template modification features to customize your AI notes.',
  path: 'ext-template-modification',
  keywords: ['edit with ai'],
  content: md,
  videoTitle: 'Template Modification',
  video:
    'https://www.loom.com/embed/9c5092bb5f964ca79ac8d878c7fec2b9?sid=ed0f24b8-5c01-404e-9fa6-27bfa59f593e',
  icon: ExtensionIcon,
}

export default article
