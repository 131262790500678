import { ContextObject } from '@counsel-project/counsel-ehr-api'
import { ContextItem, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { getRelativeTime } from '../../util'
import { removeMarkdown } from '../../util/copy'
import { removeSpeakerLabels } from '../../routes/builder/transcribe-new/lib'

export const contextObjectTypeToType = (type: ContextObject['type']): ContextItem['type'] => {
  switch (type) {
    case 'treatment':
      return 'treatment-plan'
    case 'assessment':
      return 'assessment'
    case 'progress':
      return 'progress-note'
    case 'intake':
      return 'intake'
    default:
      return 'treatment-plan'
  }
}

const getNoteContent = (s: RPatientSession): string => {
  if ((s.type === 'note' || s.type === 'document') && s.note) {
    return removeMarkdown(s.note || '')
  }

  if (s.dictation && s.duration && s.transcript) {
    return s.transcript ? removeSpeakerLabels(s.transcript) : removeMarkdown(s.note || '')
  }

  if (s.duration && s.transcript) {
    return `${removeSpeakerLabels(s.transcript)}\n(Session lasted ${getRelativeTime(
      s.duration || 0
    )})`
  }

  return removeMarkdown(s.note || '')
}

export const convertSessionToContextItem = (s: RPatientSession): ContextItem => {
  return {
    id: s._id,
    title: s.summary || s.documentType || 'Untitled',
    content: getNoteContent(s),
    date: s.startedAtString || s.createdAt,
    type:
      s.type === 'session' && s.dictation
        ? 'dictation'
        : s.type === 'session' && !s.dictation
          ? 'live-session'
          : s.type === 'note'
            ? 'progress-note'
            : s.documentType === 'Treatment Plan'
              ? 'treatment-plan'
              : 'progress-note',
    ehr: false,
  }
}
