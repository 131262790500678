import DownloadIcon from '@mui/icons-material/DownloadRounded'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { getContainerHTML, markdownToHtml } from '../../util/markdown'
import ExportDocxButton from '../../components/ExportDocxButton'
import Paper from '@mui/material/Paper'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import { useNavigate } from 'react-router-dom'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import useLayouts from '../../util/auth/useLayouts'

export type LayoutExportOptionsProps = {
  loading?: boolean
  name: string
  template: string
  layout: RLayout
}

const LayoutExportOptions = ({ loading, name, template, layout }: LayoutExportOptionsProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const [, , populateLayouts] = useLayouts()

  const handleCopy = () => {
    // Copy to clipboard
    navigator.clipboard.writeText(template)

    toast.success('Copied to clipboard')
  }

  const handlePrint = () => {
    const source = markdownToHtml(template).split('<br>').join('')

    const html = getContainerHTML(source)

    const printWindow = window.open('', '_blank')
    printWindow?.document.open()
    printWindow?.document.write(html)
  }

  const handleDuplicate = useCallback(async () => {
    try {
      if (!layout) return

      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.layouts.create({
        name: name + ' (Copy)',
        token: '',
        maxDocuments: 3,
        maxNotes: 3,
        maxObservations: 3,
        tags: ['Custom'],
        allowDictation: true,
        allowTranscript: true,
        priority: 999,
        type: 'document',
        includeDictations: true,
        includeNotes: true,
        includeTranscripts: false,
        transferTranscripts: false,
        requiredLayouts: [],
        template,
        professions: [],
        dictationDescription: layout.dictationDescription,
        sessionDescription: layout.sessionDescription,
        documentInfo: layout.documentInfo,
        description: layout.description,
        multiplePeople: layout.config.multiplePeople,
        requirePatientLabel: layout.config.requirePatientLabel,
        directoryId: layout.directoryId,
        model: layout.model,
      })

      toast.success('You are now editing the duplicated template', { id: 'duplicate' })

      navigate(`/layouts/view/${result._id}`)

      populateLayouts(true)

      window.scrollTo(0, 0)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [name, template, layout, navigate, populateLayouts])

  const filename = useMemo(() => {
    if (name) {
      return `${name}`
    }
    return 'clinical-notes'
  }, [name])

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            size="small"
            startIcon={<CopyIcon />}
            fullWidth
            onClick={handleCopy}
            disabled={loading || isLoading}
            sx={{ fontSize: 16 }}
          >
            Copy Raw Text
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            startIcon={<DownloadIcon />}
            fullWidth
            onClick={handlePrint}
            disabled={loading || isLoading}
          >
            Print / PDF Export
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ExportDocxButton
            fullWidth
            html={markdownToHtml(template || '')}
            filename={filename}
            startIcon={<MicrosoftIcon />}
            disabled={loading || isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            startIcon={<AddBoxRoundedIcon />}
            fullWidth
            onClick={handleDuplicate}
            disabled={loading || isLoading}
          >
            Duplicate Template
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default LayoutExportOptions
