import { ContextItem } from '@counsel-project/counsel-transcribe-api'
import ViewIcon from '@mui/icons-material/Visibility'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ContextTooltip from './ContextTooltip'

type ContextListItemProps = {
  item: ContextItem
  checked: boolean
  onChange: (checked: boolean) => void
}

const ContextListItem = ({ item, checked, onChange }: ContextListItemProps) => {
  return (
    <Box
      sx={{ cursor: 'pointer', borderTop: '1px solid #e0e0e0' }}
      onClick={() => onChange(!checked)}
    >
      <Grid container sx={{ mt: 1 }}>
        <Grid item>
          <Checkbox checked={checked} />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" sx={{ mr: 2 }} />
        </Grid>
        <Grid item xs>
          <Typography noWrap>{item.title}</Typography>
          <Typography color="text.secondary">{item.date}</Typography>
        </Grid>
        <Grid item>
          <ContextTooltip content={item.content}>
            <IconButton>
              <ViewIcon />
            </IconButton>
          </ContextTooltip>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContextListItem
