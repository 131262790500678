import { RLayout } from '@counsel-project/counsel-transcribe-api'
import { LayoutOption } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumbs, { BreadcrumbPath } from '../../../../components/Breadcrumbs'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import SelectableTab from '../../../../components/SelectableTab'
import PageContainer from '../../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../../util/api/transcribe-api'
import checkToken from '../../../../util/auth/checkToken'
import useLayouts from '../../../../util/auth/useLayouts'
import useRequireAdmin from '../../../../util/auth/useRequireAdmin'
import useRequireAuth from '../../../../util/auth/useRequireAuth'
import useUser from '../../../../util/auth/useUser'
import handleError from '../../../../util/handleError'
import log from '../../../../util/logging'
import ChangeEditorsDialog from '../../ChangeEditorsDialog'
import ChangeOwnerDialog from '../../ChangeOwnerDialog'
import LayoutCaptureInstructions from '../../LayoutCaptureInstructions'
import LayoutControls, { LayoutSaveOptions } from '../../LayoutControls'
import LayoutEditor from '../../LayoutEditor'
import LayoutExample from '../../LayoutExample'
import LayoutExportOptions from '../../LayoutExportOptions'
import LayoutInfoEditor from '../../LayoutInfoEditor'
import LayoutOptionsEditor from '../../LayoutOptionsEditor'

const ViewAdminLayoutPage = () => {
  const params = useParams()

  const layoutId = params.layoutId

  useRequireAuth()

  const navigate = useNavigate()

  const [, , populateLayouts] = useLayouts()

  const [user] = useUser()

  useRequireAdmin(`/layouts/view/${layoutId}`)

  const [value, setValue] = useState('')
  const [info, setInfo] = useState('')
  const [dictationDescription, setDictationDescription] = useState('')
  const [autoGenerateDictation, setAutoGenerateDictation] = useState(false)
  const [sessionDescription, setSessionDescription] = useState('')
  const [autoGenerateSession, setAutoGenerateSession] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [layout, setLayout] = useState<RLayout | undefined>(undefined)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [editorsOpen, setEditorsOpen] = useState(false)
  const [changeOwnerOpen, setChangeOwnerOpen] = useState(false)
  const [options, setOptions] = useState<LayoutOption[]>([])
  const [editMode, setEditMode] = useState<'markdown-prompt' | 'raw-prompt' | 'template'>(
    'template'
  )
  const [prompt, setPrompt] = useState('')

  const handleToggleEditorsOpen = useCallback(() => {
    setEditorsOpen((prev) => !prev)
  }, [])

  const handleToggleChangeOwnerOpen = useCallback(() => {
    setChangeOwnerOpen((prev) => !prev)
  }, [])

  const populateExamples = useCallback(async () => {
    try {
      if (!layoutId) return

      await checkToken()

      const { result } = await transcribeRequest.layouts.get({ token: '', layoutId })

      setAutoGenerateDictation(!result.dictationDescription)
      setDictationDescription(result.dictationDescription || '')
      setAutoGenerateSession(!result.sessionDescription)
      setSessionDescription(result.sessionDescription || '')
      if (
        !result.exampleGenerating ||
        !result.sessionDescriptionGenerating ||
        !result.dictationDescriptionGenerating
      ) {
        setLayout((prev) =>
          !prev
            ? undefined
            : {
                ...prev,
                dictationDescriptionGenerating: result.dictationDescriptionGenerating,
                sessionDescriptionGenerating: result.sessionDescriptionGenerating,
                exampleGenerating: result.exampleGenerating,
                example: result.example,
                sessionDescription: result.sessionDescription,
                dictationDescription: result.dictationDescription,
              }
        )
      }
    } catch (err) {
      handleError(err)
    }
  }, [layoutId])

  const populateLayout = useCallback(async () => {
    try {
      if (!layoutId) return

      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.layouts.get({ token: '', layoutId })

      setLayout(result)
      setValue(result.template || '')
      if (result.documentInfo) {
        setInfo(result.documentInfo)
      }
      setAutoGenerateDictation(!result.dictationDescription)
      setDictationDescription(result.dictationDescription || '')
      setAutoGenerateSession(!result.sessionDescription)
      setSessionDescription(result.sessionDescription || '')
      setOptions(result.config.options || [])
      setPrompt(result.prompt || '')
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [layoutId])

  const handleAutosave = useCallback(async () => {
    try {
      if (!layoutId) return
      if (!layout?.template) return
      if (layout.template.trim() === value.trim()) return
      if (!value) return
      if (editMode !== 'template') return
      if (value.length < 10) return

      setIsLoading(true)

      await checkToken()

      await transcribeRequest.layouts.update({
        token: '',
        template: value,
        layoutId,
        directoryId: layout?.directoryId || '',
        documentInfo: info || undefined,
      })

      toast.success('Template auto-saved', { id: 'auto-save', duration: 3000 })
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [value, layoutId, layout, editMode, info])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleAutosave()
    }, 5000)

    return () => clearTimeout(timeout)
  }, [value, handleAutosave])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateLayout()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateLayout])

  useEffect(() => {
    if (!layout) return
    if (
      layout.dictationDescriptionGenerating ||
      layout.sessionDescriptionGenerating ||
      layout.exampleGenerating
    ) {
      const interval = setInterval(() => {
        populateExamples()
      }, 6000)

      return () => clearInterval(interval)
    }
  }, [layout, populateExamples])

  const isOwner = user?._id === layout?.userId || user?.admin
  const isEditor =
    layout?.editors?.includes(user?.email || '') || user?.admin || user?._id === layout?.userId

  const handleSave = useCallback(
    async (options: LayoutSaveOptions) => {
      try {
        if (!layoutId) return

        setIsLoading(true)

        await checkToken()

        const dDesc =
          autoGenerateDictation || layout?.dictationDescriptionGenerating
            ? ''
            : dictationDescription
        const sDesc =
          autoGenerateSession || layout?.sessionDescriptionGenerating ? '' : sessionDescription

        const { result } = await transcribeRequest.layouts.update({
          token: '',
          ...options,
          template: editMode === 'template' ? value : undefined,
          layoutId,
          dictationDescription: dDesc,
          sessionDescription: sDesc,
          example: layout?.template !== value ? '' : undefined,
          documentInfo: info || undefined,
        })

        if (!result.config.options || result.config.options.length === 0) {
          try {
            await transcribeRequest.layouts.setOptions({
              token: '',
              layoutId,
              options: [
                {
                  type: 'checkbox',
                  categoryLabel: 'Omit Names',
                  allowMultiple: false,
                  required: false,
                  separator: ' ',
                  options: [
                    {
                      id: 'omit-names-custom-true',
                      label: 'Omit Names',
                      secondPass: `Replace all names of the client with 'client' or 'The client'. Anonymize all other names too. Keep referring to the client by their original pronouns (she/her, he/him or they/them depending on how the original document refers to the client). Keep the markdown formatting of the original document.`,
                      secondPassModel: 6,
                    },
                  ],
                },
              ],
            })
          } catch (err) {
            log.error(err)
          }
        }

        if (editMode !== 'template') {
          // set template
          await transcribeRequest.layouts.setPrompt({
            token: '',
            layoutId,
            prompt,
          })
        }

        toast.success('Layout updated successfully')

        setLayout(result)
        setValue(result.template || '')
        setDictationDescription(result.dictationDescription || '')
        setAutoGenerateDictation(!result.dictationDescription)
        setSessionDescription(result.sessionDescription || '')
        setAutoGenerateSession(!result.sessionDescription)
        setInfo(result.documentInfo || '')

        populateLayouts(true)
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [
      value,
      layoutId,
      populateLayouts,
      dictationDescription,
      autoGenerateDictation,
      sessionDescription,
      autoGenerateSession,
      layout,
      info,
      editMode,
      prompt,
    ]
  )

  const handleDeleteLayout = useCallback(async () => {
    try {
      if (!layoutId) return

      setIsLoading(true)

      await checkToken()

      await transcribeRequest.layouts.delete({ token: '', layoutId })

      toast.success('Layout deleted successfully')

      populateLayouts(true)

      navigate('/layouts/admin')
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [layoutId, navigate, populateLayouts])

  const handleSaveOptions = useCallback(
    async (options: LayoutOption[]) => {
      try {
        if (!layoutId) return

        setIsLoading(true)

        await checkToken()

        const { result } = await transcribeRequest.layouts.setOptions({
          token: '',
          layoutId,
          options,
        })

        toast.success('Options saved successfully')

        setOptions(result.config.options)
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [layoutId]
  )

  const breadcrumbs: BreadcrumbPath[] = [
    {
      name: 'Admin Templates',
      path: '/layouts/admin',
    },
    {
      name: 'View',
    },
  ]

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs>
          <Breadcrumbs paths={breadcrumbs} />
        </Grid>
        {isOwner && (
          <Grid item>
            <Button
              onClick={handleToggleChangeOwnerOpen}
              sx={{ py: 0.8 }}
              color="secondary"
              disabled={!layout?.userId}
            >
              Transfer Ownership
            </Button>
          </Grid>
        )}
        {isOwner && (
          <Grid item>
            <Button onClick={handleToggleEditorsOpen} sx={{ py: 0.8 }} disabled={!layout?.userId}>
              Manage Editors ({layout?.editors?.length || 0})
            </Button>
          </Grid>
        )}
        <Grid item>
          <IconButton color="secondary" disabled={!isOwner} onClick={() => setDeleteOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} fontSize={16}>
            Edit Mode
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            active={editMode === 'markdown-prompt'}
            onClick={() => setEditMode('markdown-prompt')}
            small
          >
            Markdown Prompt
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            active={editMode === 'raw-prompt'}
            onClick={() => setEditMode('raw-prompt')}
            small
          >
            Raw Prompt
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            active={editMode === 'template'}
            onClick={() => setEditMode('template')}
            small
          >
            Template
          </SelectableTab>
        </Grid>
        {editMode === 'template' && (
          <Grid item xs={12}>
            <LayoutInfoEditor value={info} onChange={setInfo} disabled={isLoading} />
            <Box sx={{ mt: 2 }} />
            {!!layout && (
              <LayoutEditor
                id={`layout-editor-template-${layout.identifier}`}
                readOnly={!isEditor}
                defaultValue={layout.template || ''}
                onChange={(text) => setValue(text)}
                onSaving={setSaving}
              />
            )}
          </Grid>
        )}
        {editMode === 'markdown-prompt' && (
          <Grid item xs={12}>
            {!!layout && (
              <LayoutEditor
                id={`layout-editor-markdown-${layout.identifier}`}
                readOnly={!isEditor}
                defaultValue={layout.prompt || ''}
                onChange={(text) => setPrompt(text)}
                onSaving={setSaving}
              />
            )}
          </Grid>
        )}
        {editMode === 'raw-prompt' && (
          <Grid item xs={12}>
            {!!layout && (
              <Paper elevation={0} sx={{ p: 2 }}>
                <TextField
                  id={`layout-editor-raw-${layout.identifier}`}
                  disabled={!isEditor}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  multiline
                  fullWidth
                />
              </Paper>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          {!!layout && (
            <LayoutCaptureInstructions
              generating={layout.dictationDescriptionGenerating}
              id="layout-editor-dictation-instructions"
              title="Dictation Capture Instructions"
              placeholder="Enter dictation instructions here..."
              defaultValue={layout.dictationDescription}
              onChange={setDictationDescription}
              onSaving={setSaving}
              onToggleGenerate={() => setAutoGenerateDictation((prev) => !prev)}
              autoGenerate={autoGenerateDictation}
              readOnly={!isEditor}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {!!layout && (
            <LayoutCaptureInstructions
              generating={layout.sessionDescriptionGenerating}
              id="layout-editor-session-instructions"
              title="Live Session Instructions"
              placeholder="Enter live session instructions here..."
              defaultValue={sessionDescription}
              onChange={setSessionDescription}
              onSaving={setSaving}
              onToggleGenerate={() => setAutoGenerateSession((prev) => !prev)}
              autoGenerate={autoGenerateSession}
              readOnly={!isEditor}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <LayoutExample example={layout?.example} generating={layout?.exampleGenerating} />
        </Grid>
        <Grid item xs={12}>
          <LayoutControls
            layout={layout}
            loading={isLoading}
            onSave={handleSave}
            disabled={!isEditor || saving}
          />
        </Grid>
        <Grid item xs={12}>
          {!!layout && <LayoutExportOptions name={layout.name} template={value} layout={layout} />}
        </Grid>
        {!!user?.admin && !!layout && (
          <Grid item xs={12}>
            <LayoutOptionsEditor
              admin={!!user?.admin}
              options={options}
              onSave={handleSaveOptions}
              disabled={!isEditor || isLoading}
            />
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        titleText="Delete Template"
        text="Are you sure you would like to delete this template?"
        buttonText="Delete"
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleDeleteLayout}
      />
      {layout && (
        <ChangeEditorsDialog
          open={editorsOpen}
          onClose={handleToggleEditorsOpen}
          layout={layout}
          onChangeLayout={(newLayout) => setLayout(newLayout)}
        />
      )}
      {layout && (
        <ChangeOwnerDialog
          open={changeOwnerOpen}
          onClose={handleToggleChangeOwnerOpen}
          layout={layout}
          onChangeLayout={() => navigate('/admin/layouts')}
        />
      )}
    </PageContainer>
  )
}

export default ViewAdminLayoutPage
