import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import { useSearchParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'

type TopicCardProps = {
  title: string
  description: string
  articleCount?: number
  path: string
  Icon: React.FC
}

const TopicCard = ({ title, description, articleCount, path, Icon }: TopicCardProps) => {
  const [, setSearchParams] = useSearchParams()

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    setSearchParams((prev) => {
      prev.set('page', path)
      return prev
    })
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        textDecoration: 'none',
        height: '100%',
        '&:hover': {
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        },
      }}
      onClick={handleLinkClick}
      component="a"
      href={window.location.href + path}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Icon />
        </Grid>
        <Grid item xs>
          <Typography variant="h6" fontFamily="Mukta" gutterBottom fontWeight={600}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {articleCount ? (
          <Link
            href={window.location.href + path}
            onClick={handleLinkClick}
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            See all {articleCount} articles <ChevronRightIcon sx={{ fontSize: 18 }} />
          </Link>
        ) : null}
      </Typography>
    </Paper>
  )
}

export default TopicCard
