import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'
import useUser from '../../util/auth/useUser'
import IntegrationIcon from '../../components/IntegrationIcon'

type PatientItemProps = {
  patient: RPatient
  href?: string
  onClick: (e: React.MouseEvent) => void
}

const PatientItem = ({ patient, href, onClick }: PatientItemProps) => {
  const [user] = useUser()

  return (
    <Paper
      onClick={onClick}
      sx={{
        p: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
        transition: 'background-color 0.2s ease',
        border: 'none',
        width: '100%',
        textAlign: 'left',
        display: 'block',
        height: '100%',
      }}
      elevation={4}
      component="a"
      href={href}
    >
      <Grid container spacing={1} wrap="nowrap" alignItems="center">
        {patient?.source && (
          <Grid item>
            <IntegrationIcon source={patient.source} size={18} />
          </Grid>
        )}
        <Grid item zeroMinWidth>
          <Typography
            variant="body1"
            fontWeight={400}
            noWrap
            textOverflow="ellipsis"
            sx={{ pb: 0.8 }}
            className="ph-no-capture"
          >
            {patient.label}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" color="text.secondary" noWrap>
        Added on {new Date(patient.createdAt).toLocaleDateString()}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ opacity: user?.email !== patient.email ? 1 : 0, mt: 1 }}
        className="ph-no-capture"
      >
        {patient.email}
      </Typography>
    </Paper>
  )
}

export default PatientItem
