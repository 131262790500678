import showdown from 'showdown'

export const removeMaliciousHTML = (html: string) => {
  if (!html) return ''

  // Remove script tags and their contents
  html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')

  // Remove onclick and similar event handlers
  html = html.replace(/ on\w+="[^"]*"/g, '')
  html = html.replace(/ on\w+='[^']*'/g, '')

  // Remove javascript: URLs
  html = html.replace(/javascript:[^\s"']+/gi, '')

  // Remove data: URLs
  html = html.replace(/data:[^\s"']+/gi, '')

  // Remove iframe tags
  html = html.replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi, '')

  // Remove object/embed tags
  html = html.replace(/<object\b[^<]*(?:(?!<\/object>)<[^<]*)*<\/object>/gi, '')
  html = html.replace(/<embed\b[^<]*(?:(?!<\/embed>)<[^<]*)*<\/embed>/gi, '')

  return html
}

export const getContainerHTML = (source: string) => `
<html>
  <head>
    <title>Clinical Notes AI</title>
    <!--
      Google fonts
    -->
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="anonymous" />
    <link
      href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Merriweather:wght@300;400;700&family=Mukta:wght@300;400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Varela+Round&display=swap"
      rel="stylesheet"
    />
    <style>
      @media print {
        /* Override background-color printing behavior */
        input[type="checkbox"] + label::before,
        label:has(input[type="checkbox"])::before {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          color-adjust: exact !important;
        }

        /* Ensure the checkmark prints */
        input[type="checkbox"]:checked + label::before,
        label:has(input[type="checkbox"]:checked)::before {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          color-adjust: exact !important;
        }

        /* Make the checkmark darker for better print visibility */
        input[type="checkbox"]:checked + label::after,
        label:has(input[type="checkbox"]:checked)::after {
          border-color: #000;
          border-width: 0 3px 3px 0;
        }
      }

      @page { 
        margin: 12mm;
        font-family: 'Mukta', sans-serif;
      }

      body {
        margin: 0;
        padding: 0;
        font-family: 'Mukta', sans-serif;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Comforta', sans-serif;
      }

      /* Style the checkbox container */
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Style the label that comes after a checkbox */
      input[type="checkbox"] + label,
      label:has(input[type="checkbox"]) {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        line-height: 24px;
        margin-bottom: 8px;
      }

      /* Create the custom checkbox appearance */
      input[type="checkbox"] + label::before,
      label:has(input[type="checkbox"])::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 2px solid #ddd;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
      }

      /* Hover state */
      input[type="checkbox"] + label:hover::before,
      label:has(input[type="checkbox"]):hover::before {
        border-color: #2196F3;
      }

      /* Checked state */
      input[type="checkbox"]:checked + label::before,
      label:has(input[type="checkbox"]:checked)::before {
        background-color: #2196F3;
        border-color: #2196F3;
      }

      /* Create the checkmark */
      input[type="checkbox"]:checked + label::after,
      label:has(input[type="checkbox"]:checked)::after {
        content: '';
        position: absolute;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

    </style>
    <script>
      function step1() {setTimeout("step2()", 400);}function step2() { window.print();}
      step1()
    </script>
  </head>
  <body style="margin: 0; padding: 0;" onload="step1()">
    <div class="container">
      ${removeMaliciousHTML(source)}
    </div>
  </body>
</html>
`

export const getCheckboxElement = (checked: boolean, text: string) =>
  `
<li class="task-list-item" style="list-style-type: none;">
<input type="checkbox" disabled style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;" ${
    checked ? 'checked' : ''
  }> ${removeMaliciousHTML(text)}
</li>
`.trim()

export const fixHtml = (html: string) => {
  let result = html

  // result = result.replace(/<\/h1>\n/g, '</h1><br/>')
  // result = result.replace(/<\/h2>\n/g, '</h2><br/>')
  // result = result.replace(/<\/h3>\n/g, '</h3><br/>')
  // result = result.replace(/<\/h4>\n/g, '</h4><br/>')
  // result = result.replace(/<\/h5>\n/g, '</h5><br/>')
  // result = result.replace(/<\/h6>\n/g, '</h6><br/>')
  // result = result.replace(/<\/p>\n/g, '</p><br/>')
  // result = result.replace(/<\/strong>\n/g, '</b><br/>')
  result = result.replace(/<strong>/g, '<b>')
  result = result.replace(/<\/strong>/g, '</b>')
  // result = result.replace(/\n<b>/g, '<br/><b>')

  result = result.replace(
    /- \[x\](.*?)(<br>|\n)/g,
    `<input type="checkbox" disabled style="vertical-align: middle;" checked>$1`
  )
  result = result.replace(
    /- \[ \](.*?)(<br>|\n)/g,
    `<input type="checkbox" disabled style="vertical-align: middle;">$1`
  )

  result = result.replace(/<\/table>\n<table>/g, '</table>\n<br/><table>')

  // Replace text wrapped inside ** with <strong> tag
  result = result.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

  return removeMaliciousHTML(result)
}

export const fixMarkdown = (markdown: string) => {
  let result = markdown

  // result = result.replace(/<b>/g, '**')
  // result = result.replace(/<\/b>/g, '**')

  // result = result.replace(
  //   /<input type="checkbox" disabled="" style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;" checked="">\n\n/g,
  //   '[x] '
  // )
  // result = result.replace(
  //   /<input type="checkbox" disabled="" style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;">\n\n/g,
  //   '[ ] '
  // )

  // result = result.replace(
  //   /<input type="checkbox" disabled="" style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;" checked="">/g,
  //   '[x] '
  // )
  // result = result.replace(
  //   /<input type="checkbox" disabled="" style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;">/g,
  //   '[ ] '
  // )

  // result = result.replace(/\n\n /g, '')
  // result = result.replace(/<br>/g, '\n')

  result = result.replace(/```ai-exportable/g, '')
  result = result.replace(/```exportable/g, '')
  result = result.replace(/```markdown/g, '')
  result = result.replace(/```/g, '')

  return result
}

export const markdownToHtml = (markdownText: string) => {
  const converter = new showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    strikethrough: true,
    tasklists: true,
    simpleLineBreaks: true,
  })

  const result = converter.makeHtml(fixMarkdown(markdownText))
  return removeMaliciousHTML(fixHtml(result))
}

export const htmlToMarkdown = (htmlText: string) => {
  const converter = new showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    strikethrough: true,
    tasklists: true,
    simpleLineBreaks: false,
  })

  const result = converter.makeMarkdown(htmlText)
  return removeMaliciousHTML(fixMarkdown(result))
}
