import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import { RTranscriptLog } from '@counsel-project/counsel-transcribe-api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'
import { deidentifierRequest } from '../../util/api/deidentifier-api'

const SuccessPaper = styled(Paper)({
  backgroundColor: '#dfd',
  boxShadow: 'none',
})

const WarningPaper = styled(Paper)({
  backgroundColor: '#ffd',
  boxShadow: 'none',
})

const ErroredPaper = styled(Paper)({
  backgroundColor: '#fdd',
  boxShadow: 'none',
})

type TranscriptDeidMappingProps = {
  transcriptLog: Omit<RTranscriptLog, 'transcript'>
}

const TranscriptDeidMapping = ({ transcriptLog }: TranscriptDeidMappingProps) => {
  const [deidMapping, setDeidMapping] = useState<RTranscript | null>(null)
  const [loading, setLoading] = useState(false)

  const populateDeidMapping = useCallback(async () => {
    try {
      setLoading(true)
      const dateShiftValue = 1000 * 60 * 30

      const startDate = new Date(transcriptLog.createdAt)
      startDate.setMinutes(startDate.getMinutes() - dateShiftValue)

      const endDate = new Date(transcriptLog.createdAt)
      endDate.setMinutes(endDate.getMinutes() + dateShiftValue)

      const { results: initialResults } = await deidentifierRequest.admin.list({
        token: '',
        search: transcriptLog._id,
      })

      if (initialResults.length === 0) {
        console.log('Using backup search')
        const { results } = await deidentifierRequest.admin.list({
          token: '',
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          minDuration: transcriptLog.duration - 1,
          maxDuration: transcriptLog.duration + 1,
          search: transcriptLog.userId,
        })

        setDeidMapping(results[0])
      } else {
        setDeidMapping(initialResults[0])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [transcriptLog])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateDeidMapping()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateDeidMapping])

  if (loading) {
    return <CircularProgress />
  }

  if (transcriptLog.duration < 500) {
    return (
      <WarningPaper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body1" component="p">
          Duration &lt; 500s
        </Typography>
      </WarningPaper>
    )
  }

  if (transcriptLog.type !== 'live-session') {
    return (
      <WarningPaper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body1" component="p">
          Transcript type is not live session
        </Typography>
      </WarningPaper>
    )
  }

  if (!deidMapping) {
    return (
      <ErroredPaper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body1" component="p">
          No mapping found
        </Typography>
      </ErroredPaper>
    )
  }

  return (
    <SuccessPaper elevation={0} sx={{ p: 2 }}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">
            Deidentified Material
            <br />
            <Typography variant="body1" component="span">
              {deidMapping.duration}s
            </Typography>
            <br />
            <Typography variant="body1" component="span">
              Transcript ID: {deidMapping?.transcriptId}
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {deidMapping.transcript?.split('\n')?.map((line, i) => (
              <span key={i} className="data-hj-suppress ph-no-capture">
                {line}
                <br />
              </span>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </SuccessPaper>
  )
}

export default TranscriptDeidMapping
