import { DirectorySettings } from '@counsel-project/counsel-auth-api/dist/common/database/Directory'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { capitalize } from '@counsel-project/client-utils'
import { default as BackIcon } from '@mui/icons-material/ArrowBackRounded'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import MoveDownIcon from '@mui/icons-material/MoveDownRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import SearchBar from '../../components/SearchBar'
import SelectableTab from '../../components/SelectableTab'
import MoveSessionsDialog from '../../components/layout/MoveSessionsDialog'
import PageContainer from '../../components/layout/PageContainer'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache, refreshSessionsCache } from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import PatientDeleteDialog from './PatientDeleteDialog'
import PatientInfoDialog from './PatientInfoDialog'
import PatientReassignDialog from './PatientReassignDialog'
import SessionsSection from './SessionsSection'
import UploadDocumentSection from './UploadDocumentSection'
import IntegrationIcon from '../../components/IntegrationIcon'
import PatientLinkDialog from '../../components/PatientLinkDialog'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'
import { ehrRequest } from '../../util/api/ehr-api'
import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'

const ClientPage = () => {
  useTitle('Clinical Notes AI - Client')
  const navigate = useNavigate()

  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab') || 'all'
  const ref = searchParams.get('ref')

  const theme = useTheme()

  const [patient, setPatient] = useState<null | RPatient>(null)
  const [integrationPatient, setIntegrationPatient] = useState<null | RIntegrationPatient>(null)

  const [stagedPatientLabel, setStagedPatientLabel] = useState('')
  const [renameLoading, setRenameLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const [search, setSearch] = useState('')
  const [submittedSearch, setSubmittedSearch] = useState('')
  const [moveSessionsOpen, setMoveSessionsOpen] = useState(false)

  const [patientInfoOpen, setPatientInfoOpen] = useState(false)
  const [reassignOpen, setReassignOpen] = useState(false)
  const [ehrLinkOpen, setEhrLinkOpen] = useState(false)

  const [renderSections, setRenderSections] = useState(true)
  const [canEdit, setCanEdit] = useState(false)
  const [directorySettings, setDirectorySettings] = useState<null | DirectorySettings>(null)
  const [hasEhr, setHasEhr] = useState(false)

  const [user] = useUser()

  const handleChangeTab = useCallback(
    (tab: string) => {
      setSearchParams((prev) => {
        prev.set('tab', tab)
        return prev
      })
    },
    [setSearchParams]
  )

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (!e.target.value) {
      setSubmittedSearch('')
      handleChangeTab('all')
    }
  }

  const handleSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    setSubmittedSearch(search)
    handleChangeTab('search')
  }

  const populatePatient = useCallback(async () => {
    try {
      if (!id) return

      await checkToken()

      const { result, directorySettings, editable } = await transcribeRequest.patients.get({
        token: '',
        patientId: id,
      })

      setPatient(result)
      setStagedPatientLabel(result.label)

      setCanEdit(editable)
      setDirectorySettings(directorySettings)
    } catch (err) {
      handleError(err)
    }
  }, [id])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populatePatient()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatient])

  const populateIntegrationPatient = useCallback(async () => {
    try {
      if (!patient) return
      if (!patient.integrationPatientId) return
      if (!hasEhr) return

      const { result } = await ehrRequest.integrations.patients.get({
        token: '',
        patientId: patient.integrationPatientId,
      })

      setIntegrationPatient(result)
    } catch (err) {
      handleError(err)
    }
  }, [patient, hasEhr])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateIntegrationPatient()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateIntegrationPatient])

  const populateHasEhr = useCallback(async () => {
    try {
      const { results } = await ehrRequest.integrations.patients.list({
        token: '',
        limit: 1,
      })

      setHasEhr(results.length > 0)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateHasEhr()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateHasEhr])

  const patientNomenclature = usePatientNomenclature()

  const handleToggleMoveSessions = () => {
    setMoveSessionsOpen((prev) => !prev)
  }

  const handleRenamePatient = useCallback(async () => {
    try {
      if (!id) return
      if (!patient?.label) return
      if (!stagedPatientLabel) return

      await checkToken()

      setRenameLoading(true)

      const { result } = await transcribeRequest.patients.update({
        token: '',
        patientId: id,
        label: stagedPatientLabel,
      })

      refreshPatientsCache()

      setPatient(result)
      setStagedPatientLabel(result.label)
    } catch (err) {
      handleError(err)
    } finally {
      setRenameLoading(false)
    }
  }, [id, stagedPatientLabel, patient])

  const handleToggleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev)
  }

  const handleAddSession = () => {
    refreshSessionsCache()
    setSubmittedSearch('')
    setRenderSections(false)
    setTimeout(() => {
      setRenderSections(true)
    }, 10)
  }

  const handleClearSessions = () => {
    window.location.reload()
  }

  const sectionTitle = useMemo(() => {
    if (tab === 'all') return 'All'
    if (tab === 'progress notes') return 'Progress Notes'
    if (tab === 'intake') return 'Intake Notes'
    if (tab === 'sessions') return 'Live / Dictation Sessions'
    return ''
  }, [tab])

  const sectionDocumentType = useMemo(() => {
    if (tab === 'all') return undefined
    if (tab === 'progress notes') return 'Progress Note'
    if (tab === 'intake') return 'Intake'
    if (tab === 'sessions') return undefined
    return undefined
  }, [tab])

  const sectionType = useMemo(() => {
    if (tab === 'all') return undefined
    if (tab === 'progress notes') return undefined
    if (tab === 'intake') return undefined
    if (tab === 'sessions') return 'ne'
    if (tab === 'documents') return 'document'
    return undefined
  }, [tab])

  const handleOnRemovedSessions = useCallback(() => {
    setSearch(' ')
    setSubmittedSearch(' ')
    handleChangeTab('sessions')
    refreshSessionsCache()
  }, [handleChangeTab])

  return (
    <PageContainer>
      <Box sx={{ mb: 2 }}>
        <Grid container>
          <Grid item xs>
            <Button
              href="/sessions"
              onClick={(e) => {
                e.preventDefault()
                navigate(typeof ref === 'string' ? ref : '/sessions')
              }}
              startIcon={<BackIcon />}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={handleToggleMoveSessions}
              endIcon={<MoveDownIcon />}
              variant="outlined"
              disabled={!canEdit}
            >
              Move Files
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              onClick={handleToggleDeleteOpen}
              endIcon={<DeleteIcon />}
              variant="outlined"
              disabled={!canEdit}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Paper elevation={0}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: 12,
                borderRadius:
                  theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
                overflow: 'hidden',
              }}
            >
              <LinearProgress
                sx={{
                  opacity: !patient ? 1 : 0,
                  transition: 'opacity 0.3s ease',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            {!patient && (
              <>
                <Skeleton
                  variant="rectangular"
                  height={100}
                  sx={{ backgroundColor: theme.palette.background.default, mb: 2 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ backgroundColor: theme.palette.background.default, mb: 2 }}
                />
              </>
            )}
            <Grid container spacing={2}>
              {!!patient && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {!!patient?.source && (
                      <Grid item>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <IntegrationIcon source={patient.source} size={24} />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs>
                      <TextField
                        placeholder={`Type Your ${capitalize(patientNomenclature)} Label...`}
                        value={stagedPatientLabel}
                        onChange={(e) => setStagedPatientLabel(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{
                          '& .MuiInputBase-root': {
                            fontSize: '1.2rem',
                            fontWeight: 500,
                          },
                          mb: 1,
                        }}
                        disabled={!canEdit}
                      />
                    </Grid>
                    {stagedPatientLabel !== patient.label && (
                      <Grid item>
                        <Button onClick={handleRenamePatient} disabled={renameLoading || !canEdit}>
                          Rename
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm>
                      <Typography variant="body2" color="text.secondary">
                        Added on <strong>{new Date(patient.createdAt).toLocaleDateString()}</strong>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {user?.email === patient.email ? (
                          <strong>Assigned to me</strong>
                        ) : (
                          <>
                            Assigned to <strong>{patient.email}</strong>
                          </>
                        )}
                      </Typography>
                    </Grid>
                    {hasEhr && (
                      <Grid item xs={12} sm="auto">
                        <Button
                          fullWidth
                          onClick={() => setEhrLinkOpen(true)}
                          color="primary"
                          startIcon={<ExtensionIcon />}
                        >
                          {!!patient?.source ? 'Linked to EHR' : 'Link to EHR'}
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12} sm="auto">
                      <Button fullWidth onClick={() => setReassignOpen(true)} color="secondary">
                        Reassign
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <Button fullWidth onClick={() => setPatientInfoOpen(true)}>
                        {capitalize(patientNomenclature)} Information
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
          {patient && canEdit && (
            <Box sx={{ px: 2, pb: 2 }}>
              <UploadDocumentSection patient={patient} onAddSession={handleAddSession} />
            </Box>
          )}
        </Paper>
      </Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs>
          <SelectableTab small active={tab === 'all'} onClick={() => handleChangeTab('all')}>
            All
          </SelectableTab>
        </Grid>
        <Grid item xs>
          <SelectableTab
            small
            active={tab === 'progress notes'}
            onClick={() => handleChangeTab('progress notes')}
          >
            Progress
          </SelectableTab>
        </Grid>
        <Grid item xs>
          <SelectableTab small active={tab === 'intake'} onClick={() => handleChangeTab('intake')}>
            Intake
          </SelectableTab>
        </Grid>
        <Grid item xs>
          <SelectableTab
            small
            active={tab === 'documents'}
            onClick={() => handleChangeTab('documents')}
          >
            Documents
          </SelectableTab>
        </Grid>
        <Grid item xs>
          <SelectableTab
            small
            active={tab === 'sessions'}
            onClick={() => handleChangeTab('sessions')}
          >
            Sessions
          </SelectableTab>
        </Grid>
      </Grid>
      <SearchBar
        onChange={handleChangeSearch}
        value={search}
        onSubmit={handleSubmitSearch}
        onClick={handleSubmitSearch}
      />
      {patient && tab !== 'search' && renderSections && (
        <SessionsSection
          title={sectionTitle}
          patientId={patient._id}
          documentType={sectionDocumentType}
          type={sectionType}
        />
      )}
      {patient && tab === 'search' && (
        <SessionsSection
          title={'Search Results'}
          patientId={patient._id}
          search={submittedSearch}
        />
      )}
      {patient && (
        <MoveSessionsDialog
          open={moveSessionsOpen}
          onClose={handleToggleMoveSessions}
          patientId={patient._id}
          onMoved={handleClearSessions}
        />
      )}
      {patient && (
        <PatientDeleteDialog
          open={deleteOpen}
          onClose={handleToggleDeleteOpen}
          patientId={patient._id}
          patientLabel={patient.label}
          onClear={handleOnRemovedSessions}
          onUnassign={populatePatient}
        />
      )}
      {patient && (
        <PatientInfoDialog
          open={patientInfoOpen}
          onClose={() => setPatientInfoOpen(false)}
          patient={patient}
          onSaved={setPatient}
          hidden={canEdit ? undefined : ['auto-generate', 'save']}
        />
      )}
      {patient && (
        <PatientReassignDialog
          open={reassignOpen}
          onClose={() => setReassignOpen(false)}
          patient={patient}
          onChangePatient={setPatient}
        />
      )}
      {patient && (
        <PatientLinkDialog
          open={ehrLinkOpen}
          onClose={() => setEhrLinkOpen(false)}
          initialPatient={patient}
          initialIntegrationPatient={integrationPatient}
          onSave={(patient, integrationPatient) => {
            setPatient(patient)
            setStagedPatientLabel(patient.label)
            setEhrLinkOpen(false)
          }}
        />
      )}
    </PageContainer>
  )
}

export default ClientPage
