import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type TranscriptContentProps = {
  transcript: RTranscript
}

const TranscriptContent = ({ transcript }: TranscriptContentProps) => {
  return (
    <>
      <Typography variant="body1" component="p">
        {new Date(transcript.createdAt).toString()}
      </Typography>
      <Typography variant="body1" component="p">
        Patient: <strong>{transcript.patient}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Identity: <strong>{transcript.identity}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        inputTokens: <strong>{transcript.inputTokens}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        outputTokens: <strong>{transcript.outputTokens}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        length: <strong>{transcript.transcript?.length}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Duration: <strong>{transcript.duration}s</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Patient Iteration: <strong>{transcript.patientIncrement}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Deidentifier Version: <strong>{transcript.deidentifierVersion}</strong>
      </Typography>
      <Typography variant="subtitle2" component="p" sx={{ mt: 2 }}>
        {transcript.tags.map((tag, i) => (
          <span key={i}>
            {tag}
            {i < transcript.tags.length - 1 && ', '}
          </span>
        ))}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" component="p">
              AI Generated Material
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {transcript.transcript?.split('\n')?.map((line, i) => (
                <span key={i} className="data-hj-suppress ph-no-capture">
                  {line}
                  <br />
                </span>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default TranscriptContent
