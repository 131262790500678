import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import { useSearchParams } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
type NextTopicCardProps = {
  title: string
  description: string
  path: string
}

const NextTopicCard = ({ title, description, path }: NextTopicCardProps) => {
  const [searchParams, setSearchResults] = useSearchParams()

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    setSearchResults((prev) => {
      prev.set('prev', searchParams.get('page') ?? '')
      prev.set('page', path)
      return prev
    })
  }

  const handleClickSeeAll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setSearchResults((prev) => {
      prev.delete('prev')
      prev.delete('page')
      return prev
    })
  }

  return (
    <Paper
      elevation={0}
      sx={({ palette }) => ({
        p: 2,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        textDecoration: 'none',
        outline: `2px solid ${palette.primary.main}`,
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, 0.05),
        },
      })}
      onClick={handleLinkClick}
      component="a"
      href={window.location.href + path}
    >
      <Typography variant="h6" fontFamily="Mukta" gutterBottom fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        <Link
          href={window.location.href + path}
          onClick={handleClickSeeAll}
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          See all <ChevronRightIcon sx={{ fontSize: 18 }} />
        </Link>
      </Typography>
    </Paper>
  )
}

export default NextTopicCard
