type IntegrationIconProps = {
  source: string
  size?: number
}

const IntegrationIcon = ({ source, size = 16 }: IntegrationIconProps) => {
  return (
    <img
      src={`/integrations/${source}.svg`}
      alt={source}
      style={{ width: size, height: size, objectFit: 'contain' }}
    />
  )
}

export default IntegrationIcon
