import { capitalize } from '@counsel-project/client-utils'
import { ContextItem, RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { convertSessionToContextItem } from '../../../components/context/_helpers'
import ContextSelector from '../../../components/context/ContextSelector'
import LayoutOptionsSelector from '../../../components/forms/LayoutOptionsSelector'
import PageContainer from '../../../components/layout/PageContainer'
import LicenseCard from '../../../components/licensing/LicenseCard'
import PagePaperLoader from '../../../components/loaders/PagePaperLoader'
import PatientSelector from '../../../components/PatientSelector'
import TextAccordion from '../../../components/TextAccordion'
import { usePatientNomenclature } from '../../../util'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { refreshPatientsCache, refreshSessionsCache } from '../../../util/api/transcribe-api-cached'
import checkToken from '../../../util/auth/checkToken'
import useActiveLicense from '../../../util/auth/useActiveLicense'
import useLayouts from '../../../util/auth/useLayouts'
import handleError from '../../../util/handleError'
import log from '../../../util/logging'
import PatientInfoEditor from '../../client/PatientInfoEditor'
import { createSearchParams } from '../common'
import SessionItem from '../SessionItem'

const BuilderRegeneratePage = () => {
  useTitle('Clinical Notes AI - Generate')
  const params = useParams()
  const regenerateSessionId = params.sessionId

  const [searchParams] = useSearchParams()

  const shownSessionIds = searchParams.get('shownSessionIds')
  const layoutIdentifier = searchParams.get('layout') || ''

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffectOnce(() => {
    populateActiveLicense()
  })

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<RPatientSession | null>(null)
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [stagedSummary, setStagedSummary] = useState('')
  const [layoutOptions, setLayoutOptions] = useState<string[]>([])
  const [selectedContext, setSelectedContext] = useState<ContextItem[]>([])
  const [observations, setObservations] = useState('')
  const [patientEditorOpen, setPatientEditorOpen] = useState(false)

  const ogLayout = layouts.find((l) => l.identifier === session?.layout)

  const layout = layouts.find(
    (l) => l.identifier === session?.layout || l.identifier === layoutIdentifier
  )

  const handleChangeSummary = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedSummary(e.target.value)
  }

  // populate session ids if they are in the search query once

  const populateContextSessionIds = useCallback(async () => {
    try {
      if (!session?.contextSessionIds) return
      if (session?.contextSessionIds.length === 0) return
      await checkToken()

      const { results } = await transcribeRequest.sessions.list.all({
        token: '',
        search: {
          or: session?.contextSessionIds.map((id) => ({ _id: id })),
        },
      })

      setSelectedContext(results.map(convertSessionToContextItem))
    } catch (err) {
      log.error(err)
    }
  }, [session?.contextSessionIds])

  useEffect(() => {
    if (!session?.contextSessionIds || session?.contextSessionIds.length === 0) return
    populateContextSessionIds()
  }, [session?.contextSessionIds, populateContextSessionIds])

  const handleChangeSelectedPatient = useCallback((patient: RPatient | null) => {
    setSelectedPatient(patient)
  }, [])

  const populateInitial = useCallback(async () => {
    try {
      if (!regenerateSessionId) return

      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId: regenerateSessionId,
      })

      const queryText = createSearchParams({
        shownSessionIds,
        sessionId: result.createdFrom,
      })

      if (result.state === 'generating') {
        return navigate(`/builder/generating${queryText}`)
      }

      if (
        result.state !== 'canceled' &&
        result.state !== 'errored' &&
        result.state !== 'generated'
      ) {
        // If the note has not been generated, we should not allow the user to regenerate
        toast.error('This note is not ready for regeneration')
        return navigate(`/builder${queryText}`)
      }

      if (result.patientLabel) {
        const { results } = await transcribeRequest.patients.list.all({
          token: '',
          search: {
            and: [{ label: result.patientLabel }],
          },
          limit: 1,
        })

        if (results.length > 0) {
          setSelectedPatient(results[0])
        }
      }

      setSession(result)
      setStagedSummary(result.summary || '')

      // Place user at the top of the screen
      window.scrollTo(0, 0)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [regenerateSessionId, navigate, shownSessionIds])

  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  })

  const handleRegenerateNote = useCallback(async () => {
    try {
      if (!session) return
      if (!layout) {
        toast.error('Note type is unavailable', { id: 'no-note-type' })
        return
      }

      setLoading(true)

      await checkToken()

      let newPatient: RPatient | undefined
      if (selectedPatient && !selectedPatient._id) {
        const { result } = await transcribeRequest.patients.create({
          token: '',
          label: selectedPatient.label,
          source: selectedPatient.source,
          integrationPatientId: selectedPatient.integrationPatientId,
        })
        refreshPatientsCache()
        newPatient = result
      } else if (selectedPatient?._id) {
        newPatient = selectedPatient
      }

      if (newPatient || stagedSummary) {
        await transcribeRequest.sessions.update({
          token: '',
          sessionId: session._id,
          patientId: newPatient?._id,
          summary: stagedSummary || undefined,
          expiresAt: session.expiresAt,
        })
      }

      refreshSessionsCache()

      const { result } = await transcribeRequest.sessions.generate.start({
        token: '',
        sessionId: session._id,
        identifier: layout.identifier,
        options: layoutOptions,
        sessionContext: selectedContext,
        observations,
      })
      const shownSessionIdMap = shownSessionIds
        ? [...shownSessionIds.split(','), result._id].filter(Boolean).join(',')
        : result._id

      const queryText = createSearchParams({
        shownSessionIds: shownSessionIdMap,
        patientLabel: newPatient?.label,
      })

      return navigate(
        `/sessions/${result._id}?ref=${encodeURIComponent(`/builder/generating${queryText}`)}`
      )
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [
    selectedPatient,
    stagedSummary,
    navigate,
    layout,
    layoutOptions,
    session,
    selectedContext,
    observations,
    shownSessionIds,
  ])

  const patientNomenclature = usePatientNomenclature()

  const getBuilderBackPath = useCallback(() => {
    let queryText = `?regenerate=${regenerateSessionId}`
    if (session?.createdFrom) {
      queryText += `&sessionId=${session.createdFrom}`
    }
    if (shownSessionIds) {
      queryText += `&shownSessionIds=${shownSessionIds}`
    }
    return `/builder${queryText}`
  }, [session, shownSessionIds, regenerateSessionId])

  const breadcrumbs = [
    { name: 'Select Another Type', path: getBuilderBackPath() },
    { name: 'Regenerate' },
  ]

  const handleBack = useCallback(() => {
    navigate('/builder')
  }, [navigate])

  if (!layout || !session) return <PagePaperLoader sections={[200, 300]} />

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      <Box sx={{ pb: 2 }}>
        <SessionItem
          regenerate={true}
          session={session}
          onClickClose={handleBack}
          layout={ogLayout}
        />
      </Box>
      <Typography variant="h6" fontWeight={500} sx={{ mb: 2 }}>
        Regenerate {layout.name}
      </Typography>
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Box>
          <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} fontSize={18}>
            {capitalize(layout.type)} Title
          </Typography>
          <TextField
            fullWidth
            value={stagedSummary}
            onChange={handleChangeSummary}
            placeholder={`Enter a title for this ${layout.type}...`}
          />
          <Typography variant="body1" fontWeight={500} sx={{ mt: 2, mb: 1 }} fontSize={18}>
            {capitalize(patientNomenclature)} Name{layout.config.multiplePeople ? 's' : ''}
          </Typography>
          <Box sx={{ mb: 1 }}>
            <PatientSelector value={selectedPatient} onChange={handleChangeSelectedPatient} />
          </Box>
          <Box>
            <TextAccordion
              id="patient-info-accordion"
              title={`${capitalize(patientNomenclature)} Info`}
              disabled={!selectedPatient}
              onClose={() => setPatientEditorOpen(false)}
              onOpen={() => setPatientEditorOpen(true)}
              open={patientEditorOpen}
            >
              {!!selectedPatient && (
                <PatientInfoEditor
                  hidden={['auto-generate', 'export', 'summary']}
                  patient={selectedPatient}
                  onSaved={setSelectedPatient}
                />
              )}
            </TextAccordion>
          </Box>
        </Box>
      </Paper>
      <ContextSelector
        value={selectedContext}
        onChange={setSelectedContext}
        patientId={selectedPatient?._id}
        integrationPatientId={selectedPatient?.integrationPatientId}
        observations={observations}
        onChangeObservations={setObservations}
      />
      {layout.config.options.length > 0 && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <Box>
            <LayoutOptionsSelector
              options={layout.config.options}
              value={layoutOptions}
              onChange={setLayoutOptions}
            />
          </Box>
        </Paper>
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ my: 2 }}>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={handleRegenerateNote}
            sx={{
              px: 4,
              py: 3,
            }}
            startIcon={<PsychologyRoundedIcon />}
            id="generate-notes-button"
            aria-hidden={loading}
            disabled={!session && selectedContext.length === 0}
          >
            Confirm & Regenerate
          </LoadingButton>
          <Typography variant="body1" fontWeight={500} color="primary" sx={{ mt: 2 }}>
            Regenerating will not use any credits
          </Typography>
        </Grid>
      </Grid>
      <LicenseCard
        license={activeLicense}
        hideButtons
        shownLimits={['sessions', 'dictates', 'documents']}
      />
    </PageContainer>
  )
}

export default BuilderRegeneratePage
