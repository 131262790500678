import Cookies from 'js-cookie'
import { setStore } from '../store/auth'
import { request } from '@counsel-project/client-utils'

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api'

const logout = async (storePageState?: boolean) => {
  Cookies.remove('token')
  Cookies.remove('expiresAt')
  Cookies.remove('refreshToken')
  setStore({
    user: null,
  })
  await request({
    method: 'POST',
    url: url + '/logout',
    withCredentials: true,
  })

  if (window.location.pathname === '/login') return

  // Extract UTM parameters from current URL
  const urlParams = new URLSearchParams(window.location.search)
  const utmParams = new URLSearchParams()
  for (const [key, value] of Array.from(urlParams.entries())) {
    if (key.startsWith('utm_')) {
      utmParams.append(key, value)
    }
  }

  // Build the base redirect URL
  let redirectUrl = '/login'

  if (storePageState) {
    redirectUrl += `?state=${encodeURIComponent(
      JSON.stringify({
        page: window.location.pathname + window.location.search,
      })
    )}`
    // Append UTM parameters with &
    const utmString = utmParams.toString()
    if (utmString) {
      redirectUrl += `&${utmString}`
    }
  } else {
    // If no state, UTM parameters start with ?
    const utmString = utmParams.toString()
    if (utmString) {
      redirectUrl += `?${utmString}`
    }
  }

  console.log('redirectUrl', redirectUrl)

  window.location.href = redirectUrl
}

export default logout
