import { isApiError } from '@counsel-project/client-utils'
import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded'
import SaveIcon from '@mui/icons-material/SaveRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import LayoutOptionsSelector from '../../../components/forms/LayoutOptionsSelector'
import SaveForLaterDialog from '../../../components/layout/SaveForLaterDialog'
import LicenseCard from '../../../components/licensing/LicenseCard'
import { usePatientNomenclature } from '../../../util'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import useActiveLicense from '../../../util/auth/useActiveLicense'
import useLayouts from '../../../util/auth/useLayouts'
import log from '../../../util/logging'
import MultiplePatientsSection, { PatientData } from '../MultiplePatientsSection'
import SessionItem from '../SessionItem'
import { createSearchParams } from '../common'
import LayoutPageContainer from './LayoutPageContainer'
import Step1Section from './Step1Section'
import DictationTextField from '../../../components/forms/DictationTextField'
import { getLicenseLimit } from '../../../components/licensing/util'
import { refreshPatientsCache } from '../../../util/api/transcribe-api-cached'
import EHRMultiPatientContextToggle from '../EHRMultiPatientContextToggle'
import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import handleError from '../../../util/handleError'
import { logDocument } from '../../../util/tracking'
import { convertSessionToContextItem } from '../../../components/context/_helpers'

const BuilderGroupLayoutPage = () => {
  useTitle('Clinical Notes AI - Generate')
  const params = useParams()
  const layoutId = params.id

  const [searchParams] = useSearchParams()

  const tutorial = searchParams.get('tutorial') || ''
  const sessionId = searchParams.get('sessionId') || ''
  const shownSessionIds = searchParams.get('shownSessionIds') || ''

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === layoutId)

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffectOnce(() => {
    populateActiveLicense()
  })

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<RPatientSession | null>(null)
  const [stagedSummary, setStagedSummary] = useState('')
  const [layoutOptions, setLayoutOptions] = useState<string[]>([])
  const [saveForLaterDialogOpen, setSaveForLaterDialogOpen] = useState(false)
  const [patientData, setPatientData] = useState<PatientData[]>([])
  const [context, setContext] = useState('')
  const [integrationPatients, setIntegrationPatients] = useState<RIntegrationPatient[] | null>(null)

  useEffect(() => {
    console.log(patientData)
  }, [patientData])

  const handleChangeSummary = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedSummary(e.target.value)
  }

  const populateInitial = useCallback(async () => {
    try {
      if (!sessionId) return

      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId,
      })

      const queryText = createSearchParams({
        sessionId,
        shownSessionIds,
        tutorial,
      })

      if (result.state === 'generating') {
        return navigate(`/builder/generating${queryText}`)
      }
      if (result.state === 'errored' || result.state === 'generated') {
        if (result.transcript) {
          return navigate(`/builder/regenerate/${result._id}${queryText}`)
        }
      }

      setSession(result)
      setStagedSummary(result.summary || '')

      // Place user at the top of the screen
      window.scrollTo(0, 0)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        if (err.status === 400) {
          navigate('/notes')
        }
      }
    } finally {
      setLoading(false)
    }
  }, [sessionId, navigate, tutorial, shownSessionIds])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateInitial])

  const handleGenerateNote = useCallback(async () => {
    try {
      if (!session) return
      if (!layout) {
        toast.error('Note type is unavailable', { id: 'no-note-type' })
        return
      }

      setLoading(true)

      await checkToken()

      const tryCreatePatient = async (
        patient: RPatient,
        tries?: number
      ): Promise<RPatient | null> => {
        if (!tries) tries = 0
        if (tries > 3) return null

        try {
          const { result } = await transcribeRequest.patients.create({
            token: '',
            label: patient.label,
            info: patient.info || undefined,
            summary: patient.summary || undefined,
            source: patient.source,
            integrationPatientId: patient.integrationPatientId,
          })
          refreshPatientsCache()
          return result
        } catch (err) {
          if (isApiError(err)) {
            log.error(`Failed to create patient: ${patient.label} - ${err.msg}`)
          } else {
            log.error(err)
          }
          return tryCreatePatient(patient, tries + 1)
        }
      }

      const newPatientData: PatientData[] = []
      for (const data of patientData) {
        const { patient } = data

        if (!!patient._id) {
          newPatientData.push(data)
          continue
        }

        const result = await tryCreatePatient(patient)
        if (result) {
          newPatientData.push({
            ...data,
            patient: result,
          })
        } else {
          try {
            const { results } = await transcribeRequest.patients.list.all({
              token: '',
              search: {
                and: [{ label: patient.label }],
              },
              limit: 1,
            })
            if (results.length > 0) {
              newPatientData.push({
                ...data,
                patient: results[0],
              })
            } else {
              log.error(`Failed to fetch patient: ${patient.label}`)
            }
          } catch (err) {
            if (isApiError(err)) {
              log.error(`Failed to fetch patient: ${patient.label} - ${err.msg}`)
            } else {
              log.error(err)
            }
          }
        }
      }

      const results = await Promise.all(
        newPatientData.map(async (data) => {
          const { patient, context } = data

          const { result: cloned } = await transcribeRequest.sessions.clone({
            token: '',
            sessionId: session._id,
            patientId: patient._id,
            summary: stagedSummary || undefined,
            layout: layout.identifier,
            type: layout.type,
            expiresAt: session.expiresAt,
          })

          await transcribeRequest.sessions.generate.start({
            token: '',
            sessionId: cloned._id,
            identifier: layout.identifier,
            options: layoutOptions,
            sessionContext: context,
          })

          logDocument({
            template: layout.identifier,
            name: layout.name,
          })

          return cloned
        })
      )

      const queryText = createSearchParams({
        sessionId,
        shownSessionIds: shownSessionIds
          ? [...shownSessionIds.split(',').filter(Boolean), ...results.map((s) => s._id)].join(',')
          : results.map((s) => s._id).join(','),
        tutorial,
      })

      return navigate(`/builder/generating${queryText}`)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [
    stagedSummary,
    tutorial,
    navigate,
    layout,
    layoutOptions,
    session,
    shownSessionIds,
    patientData,
    sessionId,
  ])

  const patientNomenclature = usePatientNomenclature()

  const handleBack = useCallback(() => {
    navigate('/builder')
  }, [navigate])

  const onClickSaveForLater = useCallback(() => {
    setSaveForLaterDialogOpen(true)
  }, [])

  const onCloseSaveForLater = useCallback(() => {
    setSaveForLaterDialogOpen(false)
  }, [])

  const onSaveForLater = async () => {
    toast.success('You may view this session later in the "My Clients" tab', {
      id: 'save-for-later',
      duration: 5000,
    })
    navigate('/builder')
  }

  const sessionType = useMemo(() => {
    if (!layout) return 'dictation'
    return layout.type === 'note' && session?.dictation
      ? 'dictation'
      : layout.type === 'note'
        ? 'session'
        : 'document'
  }, [layout, session])

  const licenseLimit = useMemo(() => {
    if (!activeLicense) return { max: 0, remaining: 0 }
    return getLicenseLimit(
      activeLicense,
      sessionType === 'dictation'
        ? 'dictates'
        : sessionType === 'session'
          ? 'sessions'
          : 'documents'
    )
  }, [activeLicense, sessionType])

  const canGenerate = useMemo(() => {
    if (!activeLicense) return false
    const { remaining, max } = licenseLimit
    if (max === -1) return true

    return remaining >= (patientData.length || 1)
  }, [activeLicense, patientData, licenseLimit])

  const patientLabels = useMemo(() => patientData.map((p) => p.patient.label), [patientData])

  if (!layout) return null

  if (!sessionId) {
    return <Step1Section layout={layout} />
  }

  return (
    <LayoutPageContainer title="Step 2: Generate Group Note">
      <Box sx={{ pb: 2 }}>
        <SessionItem
          regenerate={false}
          session={session}
          onClickClose={handleBack}
          layout={layout}
        />
        <Button endIcon={<SaveIcon />} onClick={onClickSaveForLater} sx={{ mt: 2 }} fullWidth>
          Save For Later
        </Button>
      </Box>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} fontSize={18}>
          {capitalize(layout.type)} Title
        </Typography>
        <TextField
          fullWidth
          value={stagedSummary}
          onChange={handleChangeSummary}
          placeholder={`Enter a title for this ${layout.type}...`}
        />
      </Paper>
      {session && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <MultiplePatientsSection mainSession={session} onChange={setPatientData} />
        </Paper>
      )}
      <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
        <Typography variant="body1" fontWeight={500} fontSize={18}>
          Group Theme
        </Typography>
        <Typography variant="body2" color="text.secondary" fontWeight={500} sx={{ mb: 1 }}>
          Discuss the group theme and topics covered to provide more context to the AI
        </Typography>
        <DictationTextField
          placeholder="Discuss the group theme..."
          value={context}
          onChange={setContext}
        />
      </Paper>
      {layout.config.options.length > 0 && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <Box>
            <LayoutOptionsSelector
              options={layout.config.options}
              value={layoutOptions}
              onChange={setLayoutOptions}
            />
          </Box>
        </Paper>
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ my: 2 }}>
        <Grid item xs={12}>
          {patientData.length === 0 && (
            <Typography variant="body1" color="secondary" fontWeight={500} sx={{ mb: 2 }}>
              Please select a list of {patientNomenclature}s to generate group notes
            </Typography>
          )}
          {!session && (
            <Paper elevation={0} sx={{ p: 2 }}>
              <Typography variant="body1" color="secondary" fontWeight={500} sx={{ mb: 2 }}>
                Please dictate information about your session to generate group notes
              </Typography>
            </Paper>
          )}
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={handleGenerateNote}
            sx={{
              px: 4,
              py: 3,
            }}
            startIcon={<PsychologyRoundedIcon />}
            id="generate-notes-button"
            aria-hidden={loading}
            disabled={
              !session ||
              patientData.length === 0 ||
              patientData.some((p) => p.loading) ||
              !canGenerate
            }
          >
            Generate Group Notes
          </LoadingButton>
          {!canGenerate && (
            <Typography variant="body1" fontWeight={500} color="secondary" sx={{ mt: 2 }}>
              You do not have enough credits to generate this group note
            </Typography>
          )}
          {licenseLimit.max !== -1 && (
            <Typography variant="body1" fontWeight={500} color="primary" sx={{ mt: 2 }}>
              {sessionType === 'dictation'
                ? `Generating will use ${patientData.length || 1} dictation credit${
                    patientData.length > 1 ? 's' : ''
                  }`
                : sessionType === 'session'
                  ? `Generating will use ${patientData.length || 1} live session credit${
                      patientData.length > 1 ? 's' : ''
                    }`
                  : `Generating will use ${patientData.length || 1} document credit${
                      patientData.length > 1 ? 's' : ''
                    }`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <LicenseCard
        license={activeLicense}
        hideButtons
        shownLimits={['sessions', 'dictates', 'documents']}
        bufferValues={
          sessionType === 'dictation'
            ? { dictates: patientData.length || 1 }
            : sessionType === 'session'
              ? { sessions: patientData.length || 1 }
              : { documents: patientData.length || 1 }
        }
      />
      <SaveForLaterDialog
        sessionId={sessionId}
        open={saveForLaterDialogOpen}
        onClose={onCloseSaveForLater}
        onSave={onSaveForLater}
      />
    </LayoutPageContainer>
  )
}

export default BuilderGroupLayoutPage
