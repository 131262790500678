import { capitalize } from '@counsel-project/client-utils'
import {
  ContextItem,
  PatientInfo,
  RPatient,
  RPatientSession,
} from '@counsel-project/counsel-transcribe-api'
import AddIconRounded from '@mui/icons-material/AddRounded'
import CloseIconRounded from '@mui/icons-material/CloseRounded'
import PersonIconRounded from '@mui/icons-material/PersonRounded'
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded'
import SaveIconRounded from '@mui/icons-material/SaveRounded'
import UploadIconRounded from '@mui/icons-material/UploadRounded'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { alpha, styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMountedState } from 'react-use'
import ClosableDialog from '../../components/ClosableDialog'
import SelectPatientContextDialog from '../../components/forms/SelectPatientContextDialog'
import PatientSelectorBase, { capitalizeInput } from '../../components/PatientSelectorBase'
import SelectableTab from '../../components/SelectableTab'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache } from '../../util/api/transcribe-api-cached'
import { visionRequest } from '../../util/api/vision-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { visionSessionsPromise } from '../../util/processPromise'
import PatientInfoEditor from '../client/PatientInfoEditor'
import { convertSessionToContextItem } from '../../components/context/_helpers'
import ContextDialog from '../../components/context/ContextDialog'
import ContextTooltip from '../../components/context/ContextTooltip'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: -16,
  right: -16,
  borderRadius: 48,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[2],
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.75),
  },
}))

export type SessionBoxContainerProps = {
  size?: number
  fullWidth?: boolean
}

const SessionBoxContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'fullWidth',
})<SessionBoxContainerProps>(({ theme, size, fullWidth }) => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: size || 100,
  width: fullWidth ? '100%' : size || 100,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `2px solid ${theme.palette.primary.main}`,
}))

export type ContextBoxProps = {
  size?: number
  fullWidth?: boolean
  item: ContextItem
  onClick?: () => void
  onClickRemove?: () => void
}

const ContextBox = ({ size, fullWidth, item, onClick, onClickRemove }: ContextBoxProps) => {
  let title = item.title || capitalize(item.type || '')
  if (title.length > 16) title = title.slice(0, 16) + '...'

  const date = item.date

  return (
    <ContextTooltip content={item.content} placement="right">
      <SessionBoxContainer onClick={onClick} size={size} fullWidth={fullWidth}>
        <StyledIconButton onClick={onClickRemove} size="small">
          <CloseIconRounded />
        </StyledIconButton>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              sx={{ p: 1 }}
              fontWeight={500}
              textOverflow="ellipsis"
              noWrap
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              sx={{ p: 1 }}
              fontWeight={500}
              variant="body2"
              color="text.secondary"
              noWrap
            >
              {date}
            </Typography>
          </Grid>
        </Grid>
      </SessionBoxContainer>
    </ContextTooltip>
  )
}

export type AddContainerProps = {
  disabled?: boolean
  size?: number
  fullWidth?: boolean
}

const AddContainer = styled('a', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'fullWidth',
})<AddContainerProps>(({ theme, size, disabled, fullWidth }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: size || 100,
  width: fullWidth ? '100%' : size || 100,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.25),
  color: theme.palette.primary.main,
  textDecoration: 'none',
}))

type AddBoxProps = {
  disabled?: boolean
  onClick?: () => void
  children?: React.ReactNode
} & AddContainerProps

const AddFileBox = ({ onClick, disabled, children, ...props }: AddBoxProps) => {
  return (
    <AddContainer onClick={!disabled ? onClick : () => {}} {...props} disabled={disabled}>
      <AddIconRounded fontSize="large" />
      {children}
    </AddContainer>
  )
}

const InvisibleFileInput = styled('input')({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
})

type UploadFileBoxProps = {
  multiple?: boolean
  accept?: string
  disabled?: boolean
  onUpload?: (files: File[]) => void
  children?: React.ReactNode
} & AddContainerProps

const UploadFileBox = ({
  onUpload,
  multiple,
  accept,
  disabled,
  children,
  ...props
}: UploadFileBoxProps) => {
  return (
    <AddContainer {...props} disabled={disabled} sx={{ position: 'relative' }}>
      <InvisibleFileInput
        multiple={multiple}
        accept={accept}
        type="file"
        onChange={(e) => {
          if (!e.target.files) return
          onUpload?.(Array.from(e.target.files))
        }}
      />
      <UploadIconRounded fontSize="large" />
      {children}
    </AddContainer>
  )
}

export type UploadPatientDocBoxProps = {
  patient: RPatient
  fullWidth?: boolean
  disabled?: boolean
  onFinish?: (item: ContextItem) => void
}

const UploadPatientDocBox = ({
  onFinish,
  patient,
  fullWidth,
  disabled,
}: UploadPatientDocBoxProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useMountedState()

  const fetchSession = useCallback(
    async (sessionId: string) => {
      try {
        await checkToken()

        const { result } = await transcribeRequest.sessions.get({
          token: '',
          sessionId,
        })

        onFinish?.(convertSessionToContextItem(result))
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [onFinish]
  )

  const handleUpload = useCallback(
    async (files: File[]) => {
      try {
        if (files.length === 0) return

        setIsLoading(true)

        const file = files[0]

        await checkToken()

        const formData = new FormData()
        formData.append('file', file)

        const { processId } = await visionRequest.sessions.generate({
          token: '',
          formData,
          patientId: patient._id,
        })

        const processPromise = visionSessionsPromise({
          processId,
        })

        toast.promise(processPromise, {
          loading: 'Uploading and processing document...',
          success: 'Document processed',
          error: 'Error processing document',
        })

        processPromise.then((s) => {
          if (!isMounted()) return
          fetchSession(s)
        })
      } catch (err) {
        handleError(err)
        setIsLoading(false)
      }
    },
    [patient, isMounted, fetchSession]
  )

  return (
    <UploadFileBox
      fullWidth={fullWidth}
      disabled={disabled || isLoading || !patient._id}
      accept=".docx,.pdf"
      onUpload={handleUpload}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography
          variant="caption"
          fontWeight={500}
          textAlign="center"
          sx={{ position: 'absolute', bottom: 8, pointerEvents: 'none' }}
        >
          docx / pdf
        </Typography>
      )}
    </UploadFileBox>
  )
}

export type PatientData = {
  patient: RPatient
  context: ContextItem[]
  loading?: boolean
}

type PatientContextDialogProps = {
  mainSession: RPatientSession
  open: boolean
  onClose: () => void
  patientData: PatientData
  onChange: (data: PatientData) => void
  onRemove?: () => void
}

const PatientContextDialog = ({
  mainSession,
  open,
  onClose,
  patientData,
  onChange,
  onRemove,
}: PatientContextDialogProps) => {
  const { patient, context } = patientData

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState<'info' | 'documents'>('documents')
  const [stagedPatient, setStagedPatient] = useState(patient)
  const [stagedContext, setStagedContext] = useState(context)
  const [addContextOpen, setAddContextOpen] = useState(false)

  const patientNomenclature = usePatientNomenclature()
  const capitalPatient = capitalize(patientNomenclature)

  useEffect(() => {
    setStagedPatient(patient)
    setStagedContext(context)
  }, [patient, context])

  const handleRemove = useCallback(() => {
    onRemove?.()
    onClose()
  }, [onRemove, onClose])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      if (stagedPatient._id) {
        const { result } = await transcribeRequest.patients.update({
          token: '',
          patientId: stagedPatient._id,
          info: stagedPatient.info || undefined,
          summary: stagedPatient.summary || undefined,
          source: stagedPatient.source,
          integrationPatientId: stagedPatient.integrationPatientId,
        })

        onChange({
          patient: result,
          context: stagedContext,
        })
      } else {
        const { result } = await transcribeRequest.patients.create({
          token: '',
          label: stagedPatient.label,
          info: stagedPatient.info || undefined,
          summary: stagedPatient.summary || undefined,
          source: stagedPatient.source,
          integrationPatientId: stagedPatient.integrationPatientId,
        })
        refreshPatientsCache()

        onChange({
          patient: result,
          context: stagedContext,
        })
      }

      onClose()

      toast.success(`Saved Context`, { id: 'save-patient-context' })
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [onChange, onClose, stagedPatient, stagedContext])

  const handleChangePatientInfo = useCallback(
    ({ info, summary }: { info: PatientInfo; summary: string }) => {
      setStagedPatient((prev) => {
        if (!prev) return prev
        return { ...prev, info, summary }
      })
    },
    []
  )

  const handleToggleAddContext = useCallback(() => {
    setAddContextOpen((prev) => !prev)
  }, [])

  const handleChangeStagedContext = useCallback((items: ContextItem[]) => {
    setStagedContext(items)
  }, [])

  const removeContextHandler = useCallback(
    (item: ContextItem) => () => {
      setStagedContext((prev) => prev.filter((s) => s.id !== item.id))
    },
    []
  )

  return (
    <>
      <ClosableDialog open={open && !addContextOpen} onClose={onClose} titleText={patient.label}>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <SelectableTab onClick={() => setTab('documents')} active={tab === 'documents'} small>
                File Context
              </SelectableTab>
            </Grid>
            <Grid item xs={6}>
              <SelectableTab onClick={() => setTab('info')} active={tab === 'info'} small>
                Info
              </SelectableTab>
            </Grid>
          </Grid>
          {tab === 'info' && (
            <Box>
              <PatientInfoEditor
                hidden={['auto-generate', 'export', 'save']}
                patient={patient}
                onChange={handleChangePatientInfo}
              />
              <Typography color="text.secondary" fontStyle="italic" sx={{ mt: 2 }}>
                This data will give the AI more context to better understand the{' '}
                {patientNomenclature}
              </Typography>
            </Box>
          )}
          {tab === 'documents' && (
            <Box>
              <Typography fontWeight={500} sx={{ mb: 2 }}>
                Add {capitalPatient} Context
              </Typography>
              <Box
                sx={(theme) => ({
                  border: `2px dashed ${theme.palette.divider}`,
                  p: 1,
                  borderRadius: 1,
                })}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm="auto">
                    <AddFileBox
                      fullWidth={xs}
                      onClick={handleToggleAddContext}
                      disabled={3 - stagedContext.length <= 0}
                    />
                  </Grid>
                  <Grid item xs={6} sm="auto">
                    <UploadPatientDocBox
                      patient={patient}
                      fullWidth={xs}
                      disabled={3 - stagedContext.length <= 0}
                      onFinish={(s) => handleChangeStagedContext([...stagedContext, s])}
                    />
                  </Grid>
                  {stagedContext.map((s, i) => (
                    <Grid item key={i} xs={6} sm="auto">
                      <ContextBox fullWidth={xs} item={s} onClickRemove={removeContextHandler(s)} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Typography color="text.secondary" fontStyle="italic" sx={{ mt: 2 }}>
                Files listed here will help AI better understand the {patientNomenclature} and
                provide a golden thread across documentation
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRemove}
            disabled={isLoading}
            color="secondary"
            startIcon={<CloseIconRounded />}
          >
            Remove {capitalPatient}
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            onClick={handleSave}
            disabled={isLoading}
            color="primary"
            startIcon={<SaveIconRounded />}
          >
            Save Changes
          </Button>
        </DialogActions>
      </ClosableDialog>
      <ContextDialog
        open={addContextOpen}
        onClose={handleToggleAddContext}
        patientId={patient._id}
        integrationPatientId={patient.integrationPatientId}
        onChange={handleChangeStagedContext}
        value={stagedContext}
      />
    </>
  )
}

type PatientChipProps = {
  patient: RPatient
  sessionsCount?: number
  loading?: boolean
  onClick: () => void
}

const PatientChip = ({ patient, sessionsCount = 0, loading, onClick }: PatientChipProps) => {
  const ChipComponent = (
    <Chip
      label={patient.label}
      onClick={onClick}
      sx={{ mr: 1, mb: 1 }}
      color="primary"
      icon={
        loading ? (
          <Box sx={{ p: '4px' }}>
            <CircularProgress size={16} />
          </Box>
        ) : !patient._id ? (
          <PersonSearchRoundedIcon />
        ) : (
          <PersonIconRounded />
        )
      }
    />
  )

  if (sessionsCount > 0) {
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {ChipComponent}
        <Avatar
          sx={{
            position: 'absolute',
            top: -8,
            right: 0,
            boxShadow: 2,
            height: 24,
            width: 24,
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            fontSize: 14,
            zIndex: 1,
          }}
        >
          {sessionsCount}
        </Avatar>
      </Box>
    )
  }

  return ChipComponent
}

type PatientInfoSectionProps = {
  mainSession: RPatientSession
  onChange: (data: PatientData[]) => void
}

const MultiplePatientsSection = ({ mainSession, onChange }: PatientInfoSectionProps) => {
  const [patient, setPatient] = useState<RPatient | null>(null)
  const [patientData, setPatientData] = useState<PatientData[]>([])
  const [selectedPatientData, setSelectedPatientData] = useState<PatientData | null>(null)
  const [patientDialog, setPatientDialog] = useState(false)

  const patientNomenclature = usePatientNomenclature()
  const capitalPatient = capitalize(patientNomenclature)

  const removePatientHandler = useCallback(
    (p: PatientData) => () => {
      setPatientData((prev) => prev.filter((c) => c.patient.label !== p.patient.label))
      toast.success(`Removed ${capitalPatient}`, { id: 'remove-patient' })
    },
    [setPatientData, capitalPatient]
  )

  useEffect(() => {
    onChange(patientData)
  }, [patientData, onChange])

  const clickPatientHandler = useCallback(
    (p: PatientData) => () => {
      setSelectedPatientData(p)
      setPatientDialog(true)
    },
    []
  )

  const setPatientDataLoading = useCallback((patientLabel: string, loading: boolean) => {
    setPatientData((prev) =>
      prev.map((p) => {
        if (p.patient.label === patientLabel) {
          return {
            ...p,
            loading,
          }
        }
        return p
      })
    )
  }, [])

  const handleUpdateContextSessions = useCallback(
    async (newPatientData: PatientData) => {
      try {
        if (newPatientData.context.length !== 0) {
          return
        }

        // Set each patient data to loading
        setPatientDataLoading(newPatientData.patient.label, true)

        await checkToken()

        const { results } = newPatientData.patient._id
          ? await transcribeRequest.sessions.list.patient({
              patientId: newPatientData.patient._id,
              token: '',
              limit: 1,
              sort: 'createdAt',
              direction: 'desc',
              search: {
                and: [
                  {
                    documentType: 'Treatment Plan',
                  },
                ],
              },
            })
          : { results: [] }

        setPatientData((prev) => [
          ...prev.filter((p) => p.patient.label !== newPatientData.patient.label),
          {
            patient: newPatientData.patient,
            context: results.filter((s) => !!s.note).map(convertSessionToContextItem),
          },
        ])
      } catch (err) {
        handleError(err)
      } finally {
        setPatientDataLoading(newPatientData.patient.label, false)
      }
    },
    [setPatientDataLoading]
  )

  const handleChangePatientData = useCallback(
    (data: PatientData) => {
      setPatientData((prev) => prev.map((p) => (p.patient.label === data.patient.label ? data : p)))
    },
    [setPatientData]
  )

  useEffect(() => {
    if (!patient) return
    const newPatientData: PatientData = {
      patient,
      context: [],
    }
    setPatientData((prev) => [...prev, newPatientData])
    handleUpdateContextSessions(newPatientData)
    setPatient(null)
    toast.success(`Added ${capitalPatient}`, { id: 'add-patient' })
  }, [patient, capitalPatient, handleUpdateContextSessions])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          {patientData.map((p, i) => (
            <Grid item key={i}>
              <PatientChip
                patient={p.patient}
                loading={p.loading}
                onClick={clickPatientHandler(p)}
                sessionsCount={p.context.length}
              />
            </Grid>
          ))}
          {patientData.length === 0 && (
            <Grid item xs={12}>
              <Typography color="text.secondary" fontStyle="italic" sx={{ mb: 2 }}>
                No {patientNomenclature}s have been selected
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography color="text.secondary" fontStyle="italic">
              Relevant {patientNomenclature} context will be associated automatically. Click on a{' '}
              {patientNomenclature} to modify contextual data
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
            What {patientNomenclature}s were involved in the session?
          </Typography>
          <PatientSelectorBase
            value={patient}
            onChange={setPatient}
            hiddenOptions={patientData.map((p) => p.patient.label)}
          />
        </Grid>
      </Grid>
      {selectedPatientData && (
        <PatientContextDialog
          mainSession={mainSession}
          open={patientDialog}
          onClose={() => setPatientDialog(false)}
          patientData={selectedPatientData}
          onChange={handleChangePatientData}
          onRemove={removePatientHandler(selectedPatientData)}
        />
      )}
    </>
  )
}

export default MultiplePatientsSection
