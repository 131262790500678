import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import getUser from './getUser'

const useRequireAuth = (page?: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    const user = getUser()
    if (!user) {
      toast.error('You must be logged in to view this page')

      // Extract UTM parameters from current URL
      const urlParams = new URLSearchParams(window.location.search)
      const utmParams = new URLSearchParams()
      for (const [key, value] of Array.from(urlParams.entries())) {
        if (key.startsWith('utm_')) {
          utmParams.append(key, value)
        }
      }

      // Build the base redirect URL
      let redirectUrl = '/login'

      if (page) {
        redirectUrl += `?state=${encodeURIComponent(JSON.stringify({ page }))}`
        // Append UTM parameters with &
        const utmString = utmParams.toString()
        if (utmString) {
          redirectUrl += `&${utmString}`
        }
      } else {
        // If no state, UTM parameters start with ?
        const utmString = utmParams.toString()
        if (utmString) {
          redirectUrl += `?${utmString}`
        }
      }

      navigate(redirectUrl)
    }
  }, [navigate, page])
}

export default useRequireAuth
