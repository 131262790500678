import { randomUUID } from '@counsel-project/components'
import { GetModelsResponse } from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/admin/models'
import { RDefaultPrompt } from '@counsel-project/counsel-transcribe-api/dist/common/database/RDefaultPrompt'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import { transcribeRequest } from '../../util/api/transcribe-api'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import DefaultPrompt from './DefaultPrompt'
import DefaultPromptDialog from './DefaultPromptDialog'

type UIModel = {
  id: string
  key: string
  value: string
}

const ModelsPage = () => {
  useTitle('Clinical Notes AI - Admin Models')
  useRequireAdmin('/')

  const [models, setModels] = useState<GetModelsResponse | null>(null)

  const [stagedModels, setStagedModels] = useState<UIModel[] | null>(null)
  const [stagedDefaultModel, setStagedDefaultModel] = useState<number | null>(null)
  const [stagedFieldsDefaultModel, setStagedFieldsDefaultModel] = useState<number | null>(null)
  const [defaultPromptOpen, setDefaultPromptOpen] = useState(false)
  const [stagedPrompt, setStagedPrompt] = useState<RDefaultPrompt | null>(null)

  const [prompts, setPrompts] = useState<RDefaultPrompt[]>([])

  const [loading, setLoading] = useState(true)

  const handlePopulateModels = useCallback(async () => {
    try {
      const response = await authRequest.admin.models.get({ token: '' })
      setModels(response)
      setStagedModels(
        Object.entries(response.models).map(([key, value]) => ({ id: randomUUID(), key, value }))
      )
      setStagedDefaultModel(response.defaultModel)
      setStagedFieldsDefaultModel(response.fieldsDefaultModel)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(handlePopulateModels, 10)
    return () => clearTimeout(timeout)
  }, [handlePopulateModels])

  // const handleAddModel = () => {
  //   if (!stagedModels) return
  //   const largestId = Math.max(...Object.keys(stagedModels).map(Number))
  //   setStagedModels({ ...stagedModels, [largestId + 1]: '' })
  // }

  // const handleRemoveModel = (id: number) => {
  //   if (!stagedModels) return
  //   setStagedModels(
  //     Object.fromEntries(Object.entries(stagedModels).filter(([key]) => parseInt(key) !== id))
  //   )
  // }

  const handleSave = useCallback(async () => {
    try {
      if (!stagedModels) return
      if (stagedDefaultModel === null) return
      if (stagedFieldsDefaultModel === null) return

      setLoading(true)

      const models = Object.fromEntries(stagedModels.map((model) => [model.key, model.value]))

      const prompt = window.prompt(JSON.stringify(models, null, 2))
      if (!prompt) return

      await authRequest.admin.models.update({
        token: '',
        models,
        defaultModel: stagedDefaultModel,
        fieldsDefaultModel: stagedFieldsDefaultModel,
      })

      toast.success('Models updated successfully')
    } catch (err) {
      console.error(err)
      toast.error('Failed to update models')
    } finally {
      setLoading(false)
    }
  }, [stagedModels, stagedDefaultModel, stagedFieldsDefaultModel])

  const handlePopulatePrompts = useCallback(async () => {
    try {
      const { results } = await transcribeRequest.prompts.list({ token: '' })
      setPrompts(results)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(handlePopulatePrompts, 10)
    return () => clearTimeout(timeout)
  }, [handlePopulatePrompts])

  const handleEditPrompt = (prompt: RDefaultPrompt) => {
    setStagedPrompt(prompt)
    setDefaultPromptOpen(true)
  }

  const handleChangePrompt = (prompt: RDefaultPrompt) => {
    setPrompts((prev) =>
      prev.find((i) => i._id === prompt._id)
        ? prev.map((p) => (p._id === prompt._id ? prompt : p))
        : [...prev, prompt]
    )
  }

  const handleClosePrompt = () => {
    setStagedPrompt(null)
    setDefaultPromptOpen(false)
  }

  const handleDeletePrompt = (prompt: RDefaultPrompt) => {
    setPrompts((prev) => prev.filter((p) => p._id !== prompt._id))
  }

  const handleOpenPrompt = () => {
    setStagedPrompt(null)
    setDefaultPromptOpen(true)
  }

  const handleAddModel = () => {
    if (!stagedModels) return
    const largestId = Math.max(...stagedModels.map((model) => parseInt(model.key)))
    setStagedModels([
      ...stagedModels,
      { id: randomUUID(), key: (largestId + 1).toString(), value: '' },
    ])
  }

  const handleRemoveModel = (index: number) => {
    if (!stagedModels) return
    setStagedModels((prev) => {
      if (!prev) return []
      const newModels = [...prev]
      newModels.splice(index, 1)
      return newModels
    })
  }

  const handleChangeModel = (index: number, key: string, value: string) => {
    if (!stagedModels) return
    setStagedModels((prev) => {
      if (!prev) return []
      const newModels = [...prev]
      newModels[index] = { id: newModels[index].id, key, value }
      return newModels
    })
  }

  if (!models || !stagedModels) return null

  return (
    <PageContainer>
      <Typography variant="h4" sx={{ mb: 2 }}>
        AI Configuration
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {prompts.map((prompt) => (
          <Grid item xs={12} key={prompt._id}>
            <DefaultPrompt value={prompt} onEdit={handleEditPrompt} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleOpenPrompt}>
            Add Prompt
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ mb: 2, mt: 4 }}>
        Models
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stagedModels.map((model, index) => (
                  <TableRow key={model.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={model.key}
                        onChange={(e) => handleChangeModel(index, e.target.value, model.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={model.value}
                        onChange={(e) => handleChangeModel(index, model.key, e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRemoveModel(index)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddModel}>
            Add
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Default Model"
            value={stagedDefaultModel}
            onChange={(e) => setStagedDefaultModel(parseInt(e.target.value))}
            type="number"
          />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            label="Field-Based Templates Default Model"
            value={stagedFieldsDefaultModel}
            onChange={(e) => setStagedFieldsDefaultModel(parseInt(e.target.value))}
            type="number"
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
            Save
          </Button>
        </Grid>
      </Grid>
      <DefaultPromptDialog
        open={defaultPromptOpen}
        onClose={handleClosePrompt}
        onChange={handleChangePrompt}
        prompt={stagedPrompt}
        onDelete={handleDeletePrompt}
      />
    </PageContainer>
  )
}

export default ModelsPage
