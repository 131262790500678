import { FieldType } from '@counsel-project/counsel-generation-api'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const allFieldTypes: FieldType[] = [
  'boolean',
  'date',
  'number',
  'string',
  'end_time',
  'start_time',
  'table',
  'multiple_choice',
  'single_choice',
  'template',
]

function getStyles(name: string, value: readonly string[], theme: Theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

type FieldTypeSelectorProps = {
  value: FieldType[]
  onChange: (value: FieldType[]) => void
  disabled?: boolean
}

const FieldTypeSelector = ({ value, onChange, disabled }: FieldTypeSelectorProps) => {
  const theme = useTheme()

  return (
    <FormControl sx={{ width: '100%' }}>
      <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
        Field Types
      </Typography>
      <Select
        labelId="layout-profession-selector-label"
        id="layout-profession-selector"
        multiple
        value={value}
        onChange={(event) => onChange(event.target.value as FieldType[])}
        input={<OutlinedInput id="layout-profession-selector-input" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {allFieldTypes.map((type) => (
          <MenuItem key={type} value={type} style={getStyles(type, value, theme)}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FieldTypeSelector
