import ExtensionIcon from '@mui/icons-material/ExtensionRounded'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import ClosableDialog from './ClosableDialog'
import { useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

const extLink =
  'https://chromewebstore.google.com/detail/clinical-notes-ai/epehnbnhpgdckdeiclihbcoihhjnmicf'

const videoSrc = '/extension-intro.mp4'

const ExtensionCard = () => {
  const [open, setOpen] = useState(false)

  const handleNavExtension = () => {
    window.open(extLink, '_blank')
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
          color: theme.palette.primary.main,
          borderRadius: 1,
          p: 1,
          px: 2,
          transition: 'background-color 0.3s ease',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
          },
        })}
        onClick={() => setOpen(true)}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <ExtensionIcon sx={(theme) => ({ color: theme.palette.primary.main })} />
          </Grid>
          <Grid item xs>
            <Typography variant="h6">Try our Chrome Extension for easy EHR integration</Typography>
          </Grid>
        </Grid>
      </Box>
      <ClosableDialog
        open={open}
        onClose={() => setOpen(false)}
        titleText="Clinical Notes AI Extension"
        maxWidth="md"
      >
        <DialogContent>
          <video
            width="100%"
            height="100%"
            src={videoSrc}
            title="Clinical Notes AI Walkthrough"
            controls
            autoPlay={open}
            style={{ aspectRatio: '16/9', backgroundColor: 'transparent' }}
            ref={(el) => {
              if (el) el.volume = 0.25
            }}
          ></video>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleNavExtension}>
            Install Now
          </Button>
        </DialogActions>
      </ClosableDialog>
    </>
  )
}

export default ExtensionCard
