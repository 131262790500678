import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Using TherapyNotes',
  description: 'Learn how to use the Clinical Notes AI Extension with TherapyNotes.',
  path: 'ext-therapynotes',
  keywords: ['getting started', 'therapynotes'],
  content: md,
  videoTitle: 'How to use Clinical Notes AI with TherapyNotes',
  video:
    'https://www.loom.com/embed/53c30cdb1bc24749b8c45c186ab927e2?sid=d998a947-f78b-4fa3-ba02-c5e21e77425b',
  linkedArticles: ['ext-template-modification'],
  icon: ExtensionIcon,
}

export default article
