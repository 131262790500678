import { PatientInfo, RPatient } from '@counsel-project/counsel-transcribe-api'
import ExportIconRounded from '@mui/icons-material/ExitToAppRounded'
import PsychologyRounded from '@mui/icons-material/PsychologyRounded'
import SaveIconRounded from '@mui/icons-material/SaveRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache } from '../../util/api/transcribe-api-cached'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import checkToken from '../../util/auth/checkToken'

type SmallInputProps = {
  title: string
  placeholder: string
  type: 'date' | 'text' | 'email'
  value: string
  onChange: (value: string) => void
}

const SmallInput = ({ title, placeholder, type, value, onChange }: SmallInputProps) => {
  return (
    <Box>
      <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
        {title}
      </Typography>
      <TextField
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        size="small"
      />
    </Box>
  )
}

export type PatientInfoEditorProps = {
  hidden?: ('auto-generate' | 'summary' | 'export' | 'save')[]
  patient: RPatient
  onSaved?: (patient: RPatient) => void
  onChange?: (patient: { info: PatientInfo; summary: string }) => void
}

const PatientInfoEditor = ({ hidden = [], patient, onSaved, onChange }: PatientInfoEditorProps) => {
  const [dob, setDob] = useState('')
  const [phone, setPhone] = useState('')
  const [emr, setEmr] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [gender, setGender] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [pronouns, setPronouns] = useState('')

  const isMobile = useMediaQuery(`(max-width: 600px)`)

  useEffect(() => {
    if (patient.summary) {
      setSummary(patient.summary)
    } else {
      setSummary('')
    }
    if (!patient.info) {
      setDob('')
      setPhone('')
      setEmr('')
      setMaritalStatus('')
      setGender('')
      setPronouns('')
      return
    }
    if (patient.info.dob) {
      setDob(patient.info.dob)
    }
    if (patient.info.phone) {
      setPhone(patient.info.phone)
    }
    if (patient.info.healthcareNumber) {
      setEmr(patient.info.healthcareNumber)
    }
    if (patient.info.maritalStatus) {
      setMaritalStatus(patient.info.maritalStatus)
    }
    if (patient.info.gender) {
      setGender(patient.info.gender)
    }
    if (patient.info.pronouns) {
      setPronouns(patient.info.pronouns)
    }
  }, [patient])

  useEffect(() => {
    if (onChange) {
      onChange({
        info: {
          dob,
          phone,
          healthcareNumber: emr,
          maritalStatus,
          gender,
          pronouns,
        },
        summary,
      })
    }
  }, [dob, phone, emr, maritalStatus, gender, onChange, summary, pronouns])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      const info: PatientInfo = {
        dob: dob || undefined,
        phone: phone || undefined,
        healthcareNumber: emr || undefined,
        maritalStatus: maritalStatus || undefined,
        gender: gender || undefined,
        pronouns: pronouns || undefined,
      }

      await checkToken()

      if (!patient._id) {
        const { result } = await transcribeRequest.patients.create({
          token: '',
          label: patient.label,
          info,
          summary: summary || undefined,
          source: patient.source,
          integrationPatientId: patient.integrationPatientId,
        })
        refreshPatientsCache()
        onSaved?.(result)
      } else {
        const { result } = await transcribeRequest.patients.update({
          token: '',
          patientId: patient._id,
          info,
          summary: summary || undefined,
          source: patient.source,
          integrationPatientId: patient.integrationPatientId,
        })
        onSaved?.(result)
      }

      toast.success('Patient information saved', {
        duration: 3000,
        id: 'patient-info-saved',
      })
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [dob, phone, gender, emr, maritalStatus, patient, summary, onSaved, pronouns])

  const isEdited = useMemo(() => {
    const dobEdited = dob !== (patient.info?.dob || '')
    const phoneEdited = phone !== (patient.info?.phone || '')
    const emrEdited = emr !== (patient.info?.healthcareNumber || '')
    const maritalStatusEdited = maritalStatus !== (patient.info?.maritalStatus || '')
    const genderEdited = gender !== (patient.info?.gender || '')
    const pronounsEdited = pronouns !== (patient.info?.pronouns || '')
    const summaryEdited = summary !== (patient.summary || '')

    return (
      dobEdited ||
      phoneEdited ||
      emrEdited ||
      maritalStatusEdited ||
      genderEdited ||
      pronounsEdited ||
      summaryEdited
    )
  }, [dob, phone, emr, maritalStatus, gender, patient, summary, pronouns])

  useEffect(() => {
    if (!patient) return
    if (!isEdited) return
    if (hidden.includes('save')) return

    const timeout = setTimeout(() => {
      handleSave()
    }, 1000)

    return () => clearTimeout(timeout)
  }, [patient, isEdited, handleSave, hidden])

  const handleExport = useCallback(() => {
    const data: Record<string, string> = {
      name: patient.label,
      dob,
      phone,
      emr,
      maritalStatus,
      gender,
      pronouns,
    }

    // Export to csv
    const csv = Object.keys(data)
      .map((key) => `${key},${data[key]}`)
      .join('\n')

    const blob = new Blob([csv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'patient-info.csv'
    a.click()
    URL.revokeObjectURL(url)
  }, [dob, phone, emr, gender, maritalStatus, patient, pronouns])

  const handleGenerateSummary = useCallback(async () => {
    try {
      setIsGenerating(true)

      await checkToken()

      const promise = transcribeRequest.patients.generateSummary({
        token: '',
        patientId: patient._id,
      })

      toast.promise(promise, {
        loading: 'Generating summary...',
        success: 'Summary generated',
        error: (err) => err.msg,
      })

      const res = await promise

      setSummary(res.summary)

      onSaved?.({
        ...patient,
        summary: res.summary,
      })
    } catch (err) {
      log.error(err)
    } finally {
      setIsGenerating(false)
    }
  }, [patient, onSaved])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
            Pronouns
          </Typography>
          <Select
            fullWidth
            value={pronouns}
            onChange={(e) => setPronouns(e.target.value)}
            size="small"
            labelId="pronouns"
            placeholder="Pronouns..."
          >
            <MenuItem value="he/him">He/Him</MenuItem>
            <MenuItem value="she/her">She/Her</MenuItem>
            <MenuItem value="they/them">They/Them</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
            Gender
          </Typography>
          <Select
            fullWidth
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            size="small"
            labelId="gender"
            placeholder="Gender..."
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SmallInput
            title="Date of Birth"
            placeholder="Date of birth here..."
            type="date"
            value={dob}
            onChange={setDob}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SmallInput
            title="Phone Number"
            placeholder="Phone number here..."
            type="text"
            value={phone}
            onChange={setPhone}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SmallInput
            title="EMR Number"
            placeholder="EMR number here..."
            type="text"
            value={emr}
            onChange={setEmr}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
            Marital Status
          </Typography>
          <Select
            fullWidth
            value={maritalStatus}
            onChange={(e) => setMaritalStatus(e.target.value)}
            size="small"
            labelId="marital-status"
            placeholder="Marital status here..."
          >
            <MenuItem value="single">Single</MenuItem>
            <MenuItem value="married">Married</MenuItem>
            <MenuItem value="divorced">Divorced</MenuItem>
            <MenuItem value="widowed">Widowed</MenuItem>
          </Select>
        </Grid>

        {!hidden.includes('summary') && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
                Summary
              </Typography>
              <TextField
                fullWidth
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                placeholder="Summary of the individual's medical history and past diagnoses here..."
                size="small"
                multiline
                minRows={isMobile ? 4 : 3}
                disabled={isGenerating}
              />
            </Box>
            {!hidden.includes('auto-generate') && (
              <LoadingButton
                startIcon={isMobile ? undefined : <PsychologyRounded />}
                loading={isGenerating}
                onClick={handleGenerateSummary}
                sx={{ mt: 2 }}
              >
                {isMobile ? <PsychologyRounded /> : 'Auto Generate'}
              </LoadingButton>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ mt: 0 }} justifyContent="end" spacing={2}>
        {!hidden.includes('export') && (
          <Grid item xs>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleExport}
              endIcon={<ExportIconRounded />}
            >
              Export Data
            </Button>
          </Grid>
        )}
        {!hidden.includes('save') && (
          <Grid item>
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={!isEdited}
              endIcon={<SaveIconRounded />}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default PatientInfoEditor
