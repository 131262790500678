import VerifyDeleteIcon from '@mui/icons-material/DeleteForeverRounded'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import SendIcon from '@mui/icons-material/SendRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'

type NoteSection = {
  title: string
  text: string
}

const convertToNoteSections = (text: string): NoteSection[] => {
  // Split sections denoted by title text wrapped in double asterisks (**)
  // along with any text that follows it until the next title
  // e.g. **Section 1**\nThis is the text for section 1.\n**Section 2**\nThis is the text for section 2.
  // will be split into:
  // [
  //   { title: 'Section 1', text: 'This is the text for section 1.' },
  //   { title: 'Section 2', text: 'This is the text for section 2.' }
  // ]
  const newText = text.trim()[0] !== '*' ? `**Note**\n${text}` : text
  const lines = newText.split('\n')
  const sections = []
  let currentSection: NoteSection = { title: '', text: '' }
  for (const line in lines) {
    if (lines[line].startsWith('**')) {
      const title = lines[line].replace(/\*\*/g, '')
      if (currentSection.title) {
        sections.push(currentSection)
      }
      currentSection = { title, text: '' }
      continue
    }
    if (currentSection.text) {
      currentSection.text += '\n'
    }
    currentSection.text += lines[line]
  }

  if (currentSection.title) {
    sections.push(currentSection)
  }

  return sections
}

const convertFromNoteSections = (sections: NoteSection[]): string => {
  return sections.map((section) => `**${section.title}**\n${section.text}`).join('\n')
}

export type NoteSectionsProps = {
  text: string
  disabled?: boolean
  onChange: (text: string) => void
  onClientEmail: (text: string) => void
  onEmail: (text: string) => void
}

const NoteSections = ({ text, disabled, onChange, onEmail, onClientEmail }: NoteSectionsProps) => {
  const [stagedDeletedIndex, setStagedDeletedIndex] = useState<number | null>(null)

  const sections = useMemo(() => convertToNoteSections(text), [text])

  const handleUpdateSection = useCallback(
    (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const text = e.target.value
      if (text.includes('*')) {
        return
      }
      const newSections = [...sections]
      newSections[index].text = text
      onChange(convertFromNoteSections(sections))
    },
    [sections, onChange]
  )

  const handleDeleteSection = useCallback(
    (index: number) => {
      if (stagedDeletedIndex === index) {
        const newSections = [...sections]
        newSections.splice(index, 1)
        onChange(convertFromNoteSections(newSections))
        setStagedDeletedIndex(null)
      } else {
        setStagedDeletedIndex(index)
      }
    },
    [sections, onChange, stagedDeletedIndex]
  )

  const handleCopySection = useCallback(
    (index: number) => {
      navigator.clipboard.writeText(sections[index].text)
      toast.success('Copied to clipboard', { id: 'copied' })
    },
    [sections]
  )

  const handleEmailSection = useCallback(
    (index: number) => {
      onEmail(sections[index].text)
    },
    [sections, onEmail]
  )

  const handleAnyEmailSection = useCallback(
    (index: number) => {
      onClientEmail(sections[index].text)
    },
    [sections, onClientEmail]
  )

  return (
    <Grid container>
      {sections.map((section, index) => (
        <Grid item key={section.title} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
            <Grid item flexGrow={1}>
              <Typography variant="subtitle2" fontSize={18} color="text.primary">
                {section.title.replace(':', '')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => handleCopySection(index)}
                sx={{ mr: 1 }}
                color="primary"
              >
                <CopyIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  if (
                    section.title === 'Client Recap:' ||
                    section.title === 'Patient Instructions:'
                  ) {
                    handleAnyEmailSection(index)
                  } else {
                    handleEmailSection(index)
                  }
                }}
                sx={{ mr: 1 }}
                color="primary"
              >
                <SendIcon />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => handleDeleteSection(index)}
                color={stagedDeletedIndex === index ? 'error' : 'secondary'}
                disabled={disabled}
              >
                {stagedDeletedIndex === index ? <VerifyDeleteIcon /> : <DeleteIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <TextField
            className="ph-no-capture"
            multiline
            fullWidth
            value={section.text}
            onChange={(e) => handleUpdateSection(index, e)}
            sx={{
              mb: 1,
            }}
            disabled={disabled}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default NoteSections
