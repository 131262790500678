import { ArticleItem } from '../types'
import md from './index.md'
import DesktopIcon from '@mui/icons-material/DesktopWindowsRounded'

const article: ArticleItem = {
  title: 'Clinical Notes AI Walkthrough',
  description: 'Learn the basics of using Clinical Notes AI to generate notes and documents.',
  path: 'walkthrough',
  keywords: ['walkthrough'],
  content: md,
  videoTitle: 'Walkthrough Video',
  video: 'https://www.youtube.com/embed/I5BBDYJLXCU?si=V5yRB2I-AgX-kAdl',
  linkedArticles: ['template-modification'],
  icon: DesktopIcon,
}

export default article
