import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Using SimplePractice',
  description: 'Learn how to use the Clinical Notes AI Extension with SimplePractice.',
  path: 'ext-simplepractice',
  keywords: ['getting started', 'simplepractice'],
  content: md,
  videoTitle: 'How to use Clinical Notes AI with SimplePractice',
  video:
    'https://www.loom.com/embed/abf433cef88f453c92c73ed0eb0cb56a?sid=3639045b-70ee-4b9a-bb99-2766a6449d06',
  linkedArticles: ['ext-template-modification'],
  icon: ExtensionIcon,
}

export default article
