import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { capitalize } from '@counsel-project/client-utils'
import TrashIcon from '@mui/icons-material/DeleteRounded'
import MoveIcon from '@mui/icons-material/MoveUpRounded'
import PsychologyRounded from '@mui/icons-material/PsychologyRounded'
import RefreshIcon from '@mui/icons-material/Refresh'
import RetryIcon from '@mui/icons-material/ReplayRounded'
import SaveIcon from '@mui/icons-material/SaveRounded'
import { DialogContent } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import ClosableDialog from '../../components/ClosableDialog'
import ConfirmDialog from '../../components/ConfirmDialog'
import EmailNoteDialog from '../../components/EmailNoteDialog'
import EmailClientDialog from '../../components/EmailPatientDialog'
import FeedbackCard from '../../components/FeedbackCard'
import GenerateMedicareCard from '../../components/GenerateMedicareCard'
import PatientSelector from '../../components/PatientSelector'
import ThankYouCard from '../../components/ThankYouCard'
import getSessionLabel from '../../components/forms/func/getSessionLabel'
import getSessionType from '../../components/forms/func/getSessionType'
import DocumentSections from '../../components/layout/DocumentSections'
import ExportSessionOptions from '../../components/layout/ExportSessionOptions'
import NoteSections from '../../components/layout/NoteSections'
import PageContainer from '../../components/layout/PageContainer'
import SessionGenerationChecker from '../../components/layout/SessionGenerationChecker'
import PagePaperLoader from '../../components/loaders/PagePaperLoader'
import { getRelativeTime, usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache, refreshSessionsCache } from '../../util/api/transcribe-api-cached'
import { DirectorySettings } from '@counsel-project/counsel-auth-api/dist/common/database/Directory'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import ChildSessionsSection from './ChildSessionsSection'
import EditWithAISection from './EditWithAISection'
import LoadingPaper from './LoadingPaper'
import OriginalSessionSection from './OriginalSessionSection'
import RevisionSection from './RevisionSection'
import SessionHeaders from './SessionHeaders'
import ViewTranscriptButton from './ViewTranscriptButton'
import TemplateFields from '../../components/layout/form/TemplateFields'
import { OutputField } from '@counsel-project/counsel-generation-api'
import Skeleton from '@mui/material/Skeleton'
import Cookies from 'js-cookie'

const SessionPage = () => {
  useTitle('Clinical Notes AI - Session')
  const navigate = useNavigate()

  const { id } = useParams()
  const [searchParams] = useSearchParams()

  // If webView cookie is present, set webView
  const webView = Cookies.get('webview') === 'true'

  const ref = searchParams.get('ref')
  const userId = searchParams.get('userId')
  const showAll = searchParams.get('showAll') === 'true'

  const [user] = useUser()

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<RPatientSession | null>(null)
  const [stagedNote, setStagedNote] = useState('')
  const [stagedSummary, setStagedSummary] = useState('')
  const [stagedFields, setStagedFields] = useState<OutputField[] | null>(null)
  const [notesLoading, setNotesLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [moveOpen, setMoveOpen] = useState(false)
  const [activePatient, setActivePatient] = useState<RPatient | null>(null)
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [emailClientDialogOpen, setEmailClientDialogOpen] = useState(false)
  const [stagedEmail, setStagedEmail] = useState('')
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [markdownSaving, setMarkdownSaving] = useState(false)
  const [canRegenerate, setCanRegenerate] = useState(false)
  const [renderedNote, setRenderedNote] = useState('')
  const [directorySettings, setDirectorySettings] = useState<DirectorySettings | null>(null)
  const [canEdit, setCanEdit] = useState(false)

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === session?.layout)

  const retentionMode =
    session?.note === undefined
      ? directorySettings?.sessionRetentionMode || 'zdr'
      : directorySettings?.noteRetentionMode || 'on-saved'

  const handleToggleMoveOpen = useCallback(() => {
    setMoveOpen((prev) => !prev)
  }, [])

  const handleToggleDeleteOpen = useCallback(() => {
    setDeleteOpen((prev) => !prev)
  }, [])

  const populateSession = useCallback(async () => {
    try {
      if (!id) return
      if (!user) return

      await checkToken()

      const { result, directorySettings, editable } = await transcribeRequest.sessions.get({
        token: '',
        sessionId: id.toString(),
      })

      if (result.state === 'generating') {
        refreshSessionsCache()
      }

      setDirectorySettings(directorySettings)

      setSession(result)
      setStagedNote(result.note ?? '')
      setStagedSummary(result.summary ?? '')
      setRenderedNote(result.note ?? '')
      setStagedFields(result.fields ?? null)

      setCanEdit(editable)
    } catch (err) {
      handleError(err)
    }
  }, [id, user])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSession()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateSession])

  const handleSaveNote = useCallback(async () => {
    try {
      if (!user) return
      if (!id) return

      await checkToken()

      setNotesLoading(true)

      const data = await transcribeRequest.sessions.update({
        sessionId: id.toString(),
        token: '',
        note: stagedNote || undefined,
        summary: stagedSummary || undefined,
        expiresAt: undefined,
        fields: stagedFields || undefined,
      })

      refreshSessionsCache()

      setSession(data.result)
      setStagedNote(data.result.note ?? '')
      setStagedSummary(data.result.summary ?? '')

      setNotesLoading(false)

      toast.success('Note saved!', { id: 'note-saved' })
    } catch (err) {
      handleError(err)
    } finally {
      setNotesLoading(false)
    }
  }, [id, stagedNote, stagedSummary, stagedFields, user])

  const handleDeleteSession = useCallback(async () => {
    try {
      if (!id) return

      await checkToken()

      setNotesLoading(true)

      await transcribeRequest.sessions.delete({
        token: '',
        sessionId: id,
      })

      refreshSessionsCache()

      toast.success('File deleted!', { id: 'note-deleted' })

      setDeleteOpen(false)
      if (webView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            close: true,
          })
        )
        return
      }

      navigate(typeof ref === 'string' ? ref : '/sessions')
    } catch (err) {
      handleError(err)
    } finally {
      setNotesLoading(false)
    }
  }, [id, navigate, ref, webView])

  const handleMoveNote = useCallback(async () => {
    try {
      if (!user) return
      if (!id) return
      if (!session) return
      if (!activePatient) return

      await checkToken()

      setNotesLoading(true)

      let patient = activePatient
      if (!activePatient._id) {
        // Find the patient if it exists
        const data = await transcribeRequest.patients.list.all({
          token: '',
          search: {
            and: [{ label: activePatient.label }],
          },
          limit: 1,
        })

        if (data.results.length > 0) {
          patient = data.results[0]
        } else {
          // Create new patient

          const { result } = await transcribeRequest.patients.create({
            token: '',
            label: activePatient.label,
            source: activePatient.source,
            integrationPatientId: activePatient.integrationPatientId,
          })
          patient = result
        }
      }

      const data = await transcribeRequest.sessions.update({
        token: '',
        sessionId: id,
        patientId: patient._id,
        expiresAt: undefined,
      })

      refreshPatientsCache()
      refreshSessionsCache()

      setSession(data.result)
      setStagedNote(data.result.note ?? '')
      setStagedSummary(data.result.summary ?? '')
      setMoveOpen(false)

      toast.success('Note moved!', { id: 'note-moved' })
    } catch (err) {
      handleError(err)
    } finally {
      setNotesLoading(false)
    }
  }, [session, activePatient, id, user])

  const handleToggleEmailDialog = () => {
    setStagedEmail(stagedNote ?? session?.note ?? '')
    setEmailDialogOpen((prev) => !prev)
  }

  const handleEmailSection = useCallback((text: string) => {
    setStagedEmail(text)
    setEmailDialogOpen((prev) => !prev)
  }, [])

  const handleChangeStagedNote = useCallback((newNote: string) => {
    setStagedNote(newNote)
  }, [])

  const patient = usePatientNomenclature()

  const handleToggleClientEmailDialog = useCallback(() => {
    setStagedEmail(stagedNote ?? '')
    setEmailClientDialogOpen((prev) => !prev)
  }, [stagedNote])

  const handleAnyEmailSection = useCallback((text: string) => {
    setStagedEmail(text)
    setEmailClientDialogOpen((prev) => !prev)
  }, [])

  const handleRetryTranscription = useCallback(async () => {
    try {
      if (!id) return

      setNotesLoading(true)

      await checkToken()

      const res = await transcribeRequest.sessions.transcribe.retry({
        token: '',
        sessionId: id,
      })

      return navigate(`/builder/transcribing/${res.result._id}`)
    } catch (err) {
      log.error(err)
    } finally {
      setNotesLoading(false)
    }
  }, [id, navigate])

  const handleGoToGenerate = useCallback(() => {
    if (!session) return
    if (session.note || session.type) {
      const refUrl = ref
      // The search parameters in the ref url
      return navigate(`/builder/regenerate/${session._id}?${refUrl?.split('?')[1] || ''}`)
    }
    if (!session.note && (!session.type || !session.layout)) {
      return navigate(`/builder?sessionId=${session._id}`)
    }

    navigate(`/builder/layout/${session.layout}?sessionId=${session._id}`)
  }, [navigate, session, ref])

  const handleUpdateSession = useCallback((newSession: RPatientSession) => {
    setSession(newSession)
    setStagedNote(newSession.note ?? '')
    setStagedSummary((prev) => (!prev ? newSession.summary ?? '' : prev))
    setRenderedNote(newSession.note ?? '')
    setStagedFields(null)
    setTimeout(() => {
      setStagedFields(newSession.fields ?? null)
    }, 10)
  }, [])

  const handleSaveFeedback = useCallback(
    async (stars: number, comment?: string) => {
      try {
        if (!session) return

        setLoading(true)

        await checkToken()

        await transcribeRequest.sessions.feedback.send({
          token: '',
          sessionId: session._id,
          stars,
          comment,
        })

        if (comment) {
          setFeedbackSent(true)
        }

        toast.success('Feedback sent!')
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [session]
  )

  const handleClickBack = useCallback(() => {
    navigate(typeof ref === 'string' ? ref : '/sessions')
  }, [navigate, ref])

  const handleNavSupport = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      navigate('/help')
    },
    [navigate]
  )

  const handleChangeOutputFields = useCallback(
    (func: (prev: OutputField[]) => OutputField[]) => {
      setStagedFields((prev) => func(prev || []))
    },
    [setStagedFields]
  )

  if (!session || !id) return <PagePaperLoader sections={[44, 181, 200]} />

  const hasGenerated = (!!session?.note || session.state === 'errored') && !!session.state

  const type = getSessionType(session)
  const isTranscribeSession = type === 'live-session' || type === 'dictation'
  const docType = getSessionLabel(session, layout)
  const lcaseDocType = docType.toLowerCase()

  return (
    <PageContainer>
      <SessionHeaders
        patientSession={session}
        disabled={loading || session.state === 'recording' || !canEdit}
        onClickBack={handleClickBack}
        onClickAssign={handleToggleMoveOpen}
      />

      <SessionGenerationChecker session={session} onChange={handleUpdateSession} />
      {session.expiresAt && session.state !== 'generating' && (
        <Paper
          sx={{
            mb: 2,
            p: 2,
          }}
          elevation={0}
        >
          <Typography variant="body1" color="error">
            Information will be deleted at{' '}
            <Typography component="span" fontWeight={500}>
              {session?.expiresAt
                ? new Date(session.expiresAt).toLocaleTimeString() +
                  ' ' +
                  new Date(session.expiresAt).toLocaleDateString()
                : 'N/A'}
            </Typography>
          </Typography>
          {retentionMode !== 'zdr' && (
            <>
              <Typography variant="body1" color="text.secondary">
                This has not been saved yet. Please save this if you want to keep it.
              </Typography>
              <Button
                sx={{ mt: 2 }}
                color="error"
                onClick={() => handleSaveNote()}
                disabled={notesLoading || session.state === 'recording' || !canEdit}
              >
                Keep This {docType}
              </Button>
            </>
          )}
        </Paper>
      )}
      <LoadingPaper loading={notesLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  fontWeight={500}
                  sx={{ fontSize: { xs: 14, sm: 20 } }}
                >
                  {docType}
                </Typography>
                {!!session?.patientLabel && (
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ fontSize: { xs: 14, sm: 20 } }}
                  >
                    For
                    <Typography
                      component="span"
                      variant="body1"
                      color="text.secondary"
                      fontWeight={500}
                      sx={{ fontSize: { xs: 14, sm: 20 } }}
                    >
                      {' '}
                      {session?.patientLabel}
                    </Typography>
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleToggleDeleteOpen}
                  color="secondary"
                  disabled={notesLoading || !canEdit}
                >
                  <TrashIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {session.state !== 'recording' && !isTranscribeSession && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} fontSize={18}>
                Title
              </Typography>
              <TextField
                fullWidth
                multiline
                value={stagedSummary}
                onChange={(e) => setStagedSummary(e.target.value.split('\n').join(''))}
                placeholder={`${docType} title...`}
                sx={{
                  mt: 1,
                }}
                disabled={notesLoading || session.state === 'generating' || !canEdit}
              />
            </Grid>
          )}
          {isTranscribeSession && (
            <Grid item xs={12}>
              {session.duration && (
                <Typography variant="body1" color="text.secondary">
                  Duration: <strong>{getRelativeTime(session.duration)}</strong>
                </Typography>
              )}
              <Typography variant="body1" color="text.secondary">
                Started at:{' '}
                <strong>
                  {session.startedAtString ||
                    new Date(session.startedAt || session.createdAt).toLocaleString()}
                </strong>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Ended on:{' '}
                <strong>
                  {session.endedAtString ||
                    new Date(session.endedAt || session.createdAt).toLocaleString()}
                </strong>
              </Typography>
              <Typography variant="body1" color="text.secondary" textOverflow="ellipsis" noWrap>
                {user?.email !== session.email ? (
                  <strong>{session.email}</strong>
                ) : (
                  'Assigned to me'
                )}
              </Typography>
              <ViewTranscriptButton sx={{ mt: 1 }} transcript={session.transcript} />
            </Grid>
          )}
        </Grid>
      </LoadingPaper>
      {!!session.note && !session.fields && (
        <Box sx={{ mb: 2 }}>
          <RevisionSection
            savedNote={session.note}
            noteVersions={session.noteVersions}
            note={stagedNote}
            onChangeNote={(newNote: string) => {
              setStagedNote(newNote)
              setRenderedNote(newNote)
            }}
            onSave={handleSaveNote}
            disabled={session.state === 'generating' || !canEdit || loading}
          />
        </Box>
      )}
      {!isTranscribeSession && !!session && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            ...(layout?.config?.displayType === 'markdown'
              ? {
                  p: 0,
                  borderRadius: 0,
                  background: 'transparent',
                }
              : {}),
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.paper',
              ...(layout?.config?.displayType === 'markdown'
                ? {
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                  }
                : {}),
            }}
          >
            {layout?.fields && stagedFields ? (
              <Box sx={{ p: 2 }} className="ph-no-capture">
                <TemplateFields
                  inputFields={layout.fields}
                  onChange={handleChangeOutputFields}
                  outputFields={stagedFields}
                />
              </Box>
            ) : layout?.config?.displayType === 'sections' || !session.type ? (
              <NoteSections
                text={stagedNote}
                onChange={handleChangeStagedNote}
                onEmail={handleEmailSection}
                onClientEmail={handleAnyEmailSection}
                disabled={session.state === 'generating' || !canEdit}
              />
            ) : session ? (
              <DocumentSections
                loading={session.state === 'generating'}
                disabled={session.state === 'generating' || !canEdit}
                id={id + '-doc-sections'}
                text={renderedNote}
                onChange={handleChangeStagedNote}
                onEmail={handleEmailSection}
                onCreateNewNote={() => {}}
                onSaving={setMarkdownSaving}
              />
            ) : (
              <>
                <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
              </>
            )}
          </Box>
          <Typography
            variant="body1"
            fontWeight={500}
            fontSize={14}
            color="text.secondary"
            fontStyle="italic"
            sx={{ mt: 2 }}
            aria-label="disclaimer"
            aria-describedby="disclaimer"
          >
            Disclaimer: This is an AI-generated document. Clinician validation and editing is
            necessary before use. Diagnostic codes such as ICD-10, DSM-5 and other suggestions are
            not a diagnosis and must be verified by the clinician.
          </Typography>
        </Paper>
      )}
      {!!session.note && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <EditWithAISection
            layout={layout}
            sessionId={id}
            onChangeSession={handleUpdateSession}
            disabled={session?.state === 'generating'}
          />
        </Paper>
      )}
      <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
        <Box>
          <Grid container spacing={2}>
            {isTranscribeSession && session?.state !== 'generating' && session.transcript && (
              <Grid item xs={12}>
                <Button
                  onClick={handleGoToGenerate}
                  disabled={notesLoading || !canEdit}
                  startIcon={<PsychologyRounded />}
                  fullWidth
                  sx={{ p: 3 }}
                >
                  Generate Documentation
                </Button>
              </Grid>
            )}
            {isTranscribeSession &&
              session?.state !== 'generating' &&
              !session.transcript &&
              session.audioFileId && (
                <Grid item xs={12}>
                  <Button
                    onClick={handleRetryTranscription}
                    disabled={notesLoading || !canEdit}
                    startIcon={<RetryIcon />}
                    fullWidth
                    sx={{ p: 3 }}
                  >
                    Retry Transcription
                  </Button>
                </Grid>
              )}
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
          {stagedNote && (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveNote()}
                  disabled={
                    !session?.note ||
                    session?.state === 'generating' ||
                    loading ||
                    notesLoading ||
                    markdownSaving ||
                    !canEdit
                  }
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {session.note && (
            <>
              <Grid item xs={12}>
                <ExportSessionOptions
                  loading={loading || markdownSaving}
                  note={stagedNote}
                  summary={session.summary}
                  patientLabel={session.patientLabel}
                  startedAtString={session.startedAtString}
                  endedAtString={session.endedAtString || session.endedAt}
                  layout={layout}
                  fields={stagedFields || undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {hasGenerated && canRegenerate && (
            <Grid item xs={12}>
              <Button
                onClick={handleGoToGenerate}
                sx={{ mr: 2 }}
                disabled={notesLoading || session?.state === 'generating' || !canEdit}
                startIcon={<RefreshIcon />}
                fullWidth
                color="secondary"
              >
                Regenerate
              </Button>
            </Grid>
          )}
          {session.state === 'recording' && (
            <Grid item xs={12}>
              <Typography fontWeight={500} variant="body1">
                It looks like this session was cut off before audio could be processed. Please
                ensure you do not leave the loading page until the audio has been processed. If this
                continues, please contact support{' '}
                <Typography component="a" href="/help" onClick={handleNavSupport}>
                  here
                </Typography>
                .
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      {hasGenerated && session.transcriptId && canEdit && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          {!feedbackSent ? (
            <FeedbackCard onSaveFeedback={handleSaveFeedback} loading={loading} />
          ) : (
            <ThankYouCard />
          )}
        </Paper>
      )}
      {session?.type === 'note' && !isTranscribeSession && canEdit && !webView && (
        <GenerateMedicareCard
          sessionId={id}
          patientLabel={session?.patientLabel || activePatient?.label}
        />
      )}
      {session && (
        <Box sx={{ mt: 2 }}>
          <OriginalSessionSection session={session} setCanRegenerate={setCanRegenerate} />
          <ChildSessionsSection session={session} />
        </Box>
      )}
      <ConfirmDialog
        open={deleteOpen}
        onClose={handleToggleDeleteOpen}
        onConfirm={handleDeleteSession}
        titleText={`Delete ${docType}`}
        text={`Are you sure you want to delete this ${lcaseDocType} forever?`}
        loading={notesLoading}
      />
      <ClosableDialog
        open={moveOpen}
        onClose={handleToggleMoveOpen}
        titleText="Move Note"
        fullScreenOnMobile
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mb: 2 }} color="text.secondary">
                Move this {lcaseDocType} to another {patient}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mb: 1 }} color="text.primary">
                {capitalize(patient)} Label
              </Typography>
              <PatientSelector
                value={activePatient}
                onChange={setActivePatient}
                hiddenOptions={[session?.patientLabel ?? '']}
                disabled={notesLoading}
              />
            </Grid>
            <Grid item justifySelf="flex-end" alignSelf="end">
              <Button
                onClick={handleMoveNote}
                endIcon={<MoveIcon />}
                disabled={
                  !activePatient || notesLoading || activePatient.label === session?.patientLabel
                }
              >
                {activePatient?._id === ''
                  ? `Move Under New ${capitalize(patient)}`
                  : !activePatient
                    ? 'Move Note'
                    : 'Move Note to ' + activePatient?.label}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ClosableDialog>
      <EmailNoteDialog
        patientLabel={session?.patientLabel ?? undefined}
        text={stagedEmail}
        open={emailDialogOpen}
        onClose={handleToggleEmailDialog}
        onSetLoading={setNotesLoading}
        startedAtString={
          session?.startedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
        endedAtString={
          session?.endedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
      />
      <EmailClientDialog
        text={stagedEmail}
        open={emailClientDialogOpen}
        onClose={handleToggleClientEmailDialog}
        patientLabel={session?.patientLabel ?? undefined}
        onSetLoading={setLoading}
        startedAtString={
          session?.startedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
        endedAtString={
          session?.endedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
      />
    </PageContainer>
  )
}

export default SessionPage
