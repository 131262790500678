import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import ClosableDialog from './ClosableDialog'
import PatientSelectorBase from './PatientSelectorBase'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import IntegrationPatientSelector from './IntegrationPatientSelector'
import { transcribeRequest } from '../util/api/transcribe-api'
import checkToken from '../util/auth/checkToken'
import handleError from '../util/handleError'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import SessionItem from '../routes/sessions/SessionItem'
import { usePatientNomenclature } from '../util'
import Tooltip from '@mui/material/Tooltip'

const DashedBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}))

export type PatientLinkDialogProps = {
  open: boolean
  onClose: () => void
  initialPatient?: RPatient | null
  initialIntegrationPatient?: RIntegrationPatient | null
  onSave: (patient: RPatient, integrationPatient: RIntegrationPatient) => void
}

const PatientLinkDialog = ({
  open,
  onClose,
  initialPatient,
  initialIntegrationPatient,
  onSave,
}: PatientLinkDialogProps) => {
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(initialPatient || null)
  const [selectedIntegrationPatient, setSelectedIntegrationPatient] =
    useState<RIntegrationPatient | null>(initialIntegrationPatient || null)
  const [patientSessions, setPatientSessions] = useState<RPatientSession[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPatientSessions, setTotalPatientSessions] = useState(0)

  useEffect(() => {
    if (open) {
      setSelectedPatient(initialPatient || null)
      setSelectedIntegrationPatient(initialIntegrationPatient || null)
    } else {
      setSelectedPatient(null)
      setSelectedIntegrationPatient(null)
    }
  }, [open, initialPatient, initialIntegrationPatient])

  const populatePatientSessions = useCallback(async () => {
    try {
      if (!selectedPatient) return

      setLoading(true)

      await checkToken()

      const data = await transcribeRequest.sessions.list.patient({
        token: '',
        patientId: selectedPatient._id,
        limit: 3,
      })

      setPatientSessions(data.results)
      setTotalPatientSessions(data.total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedPatient])

  useEffect(() => {
    if (!selectedPatient) return

    const timeout = setTimeout(populatePatientSessions, 10)

    return () => clearTimeout(timeout)
  }, [selectedPatient, populatePatientSessions])

  const handleLink = useCallback(async () => {
    try {
      if (!selectedPatient || !selectedIntegrationPatient) return

      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.patients.update({
        token: '',
        patientId: selectedPatient._id,
        integrationPatientId: selectedIntegrationPatient._id,
        source: selectedIntegrationPatient.integrationType,
        label:
          selectedIntegrationPatient.name +
          ` (${
            selectedIntegrationPatient?.metadata?.mrNumber || selectedIntegrationPatient.identifier
          })`,
      })

      setSelectedPatient(result)

      onSave(result, selectedIntegrationPatient)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedPatient, selectedIntegrationPatient, onSave, onClose])

  const patientNomenclature = usePatientNomenclature()

  const getTooltipMessage = useCallback(() => {
    if (!selectedPatient || !selectedIntegrationPatient) {
      return 'Please select both a Clinical Notes AI patient and an EHR patient'
    }
    if (selectedPatient.integrationPatientId === selectedIntegrationPatient._id) {
      return 'This patient is already linked to this EHR patient'
    }
    if (loading) {
      return 'Please wait...'
    }
    return ''
  }, [loading, selectedPatient, selectedIntegrationPatient])

  const isDisabled =
    loading ||
    selectedPatient?.integrationPatientId === selectedIntegrationPatient?._id ||
    !selectedPatient ||
    !selectedIntegrationPatient

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Link EHR Patient" maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={500} fontSize={18}>
              Link your Clinical Notes AI {patientNomenclature} to an EHR-integrated patient
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientSelectorBase
              value={selectedPatient}
              onChange={setSelectedPatient}
              placeholder="Select a patient"
              disableIntegrationPatients
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IntegrationPatientSelector
              value={selectedIntegrationPatient}
              onChange={setSelectedIntegrationPatient}
              placeholder="Select an EHR patient"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {patientSessions.map((session) => (
                <Grid item xs={12} key={session._id}>
                  <SessionItem
                    variant="outlined"
                    session={session}
                    onClick={() => {}}
                    sx={(theme) => ({
                      border: `1px dashed ${theme.palette.divider}`,
                      paddingTop: theme.spacing(2),
                      height: 'auto',
                    })}
                  />
                </Grid>
              ))}
              {totalPatientSessions > patientSessions.length && (
                <Grid item xs={12}>
                  <Typography variant="body2" fontSize={14} color="text.secondary">
                    ...{totalPatientSessions - patientSessions.length} more items
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Typography variant="body2" fontSize={14} color="text.secondary" sx={{ mt: 1 }}>
              Linking total of {totalPatientSessions} items
            </Typography>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            Cancel
          </Button>
          <Tooltip
            disableInteractive
            title={getTooltipMessage()}
            placement="bottom"
            arrow
            open={isDisabled && open}
          >
            <Button onClick={handleLink} variant="contained" disabled={isDisabled}>
              Confirm EHR Link
            </Button>
          </Tooltip>
        </DialogActions>
      </DialogContent>
    </ClosableDialog>
  )
}

export default PatientLinkDialog
