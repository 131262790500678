import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { useCallback, useState } from 'react'
import Breadcrumbs, { BreadcrumbPath } from '../../components/Breadcrumbs'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import ChangeEditorsDialog from './ChangeEditorsDialog'
import ChangeOwnerDialog from './ChangeOwnerDialog'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import { useNavigate } from 'react-router-dom'
import useUser from '../../util/auth/useUser'
import ConfirmDialog from '../../components/ConfirmDialog'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import toast from 'react-hot-toast'
import handleError from '../../util/handleError'

type LayoutHeaderProps = {
  breadcrumbs: BreadcrumbPath[]
  layout: RLayout
  onChangeLayout: (layout: RLayout) => void
}

const LayoutHeader = ({ breadcrumbs, layout, onChangeLayout }: LayoutHeaderProps) => {
  const [loading, setLoading] = useState(false)
  const [editorsOpen, setEditorsOpen] = useState(false)
  const [changeOwnerOpen, setChangeOwnerOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const navigate = useNavigate()

  const [user] = useUser()

  const handleToggleEditorsOpen = useCallback(() => {
    setEditorsOpen((prev) => !prev)
  }, [])

  const handleToggleChangeOwnerOpen = useCallback(() => {
    setChangeOwnerOpen((prev) => !prev)
  }, [])

  const isOwner = user?._id === layout?.userId || user?.admin

  const handleDeleteLayout = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await transcribeRequest.layouts.delete({ token: '', layoutId: layout._id })

      toast.success('Template deleted successfully')

      navigate('/layouts')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [navigate, layout])

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Breadcrumbs paths={breadcrumbs} />
      </Grid>
      {isOwner && (
        <Grid item>
          <Button onClick={handleToggleChangeOwnerOpen} sx={{ py: 0.8 }} color="secondary">
            Transfer Ownership
          </Button>
        </Grid>
      )}
      {isOwner && (
        <Grid item>
          <Button onClick={handleToggleEditorsOpen} sx={{ py: 0.8 }}>
            Manage Editors ({layout?.editors?.length || 0})
          </Button>
        </Grid>
      )}
      <Grid item>
        <IconButton color="secondary" disabled={!isOwner} onClick={() => setDeleteOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      {layout && (
        <ChangeEditorsDialog
          open={editorsOpen}
          onClose={handleToggleEditorsOpen}
          layout={layout}
          onChangeLayout={(newLayout) => onChangeLayout(newLayout)}
        />
      )}
      {layout && (
        <ChangeOwnerDialog
          open={changeOwnerOpen}
          onClose={handleToggleChangeOwnerOpen}
          layout={layout}
          onChangeLayout={() => navigate('/layouts')}
        />
      )}
      <ConfirmDialog
        titleText="Delete Template"
        text="Are you sure you would like to delete this template?"
        buttonText="Delete"
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleDeleteLayout}
        loading={loading}
      />
    </Grid>
  )
}

export default LayoutHeader
