import { DGLanguageSelector } from '@counsel-project/components'
import { DGLanguageCode } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DirectionButton from '../components/DirectionButton'
import PageContainer from '../components/layout/PageContainer'
import useRequireAuth from '../util/auth/useRequireAuth'
import ExtensionCard from '../components/ExtensionCard'

const Root = () => {
  useRequireAuth('/')

  const navigate = useNavigate()

  const navHandler = (path: string) => () => {
    navigate(path)
  }

  const [language, setLanguage] = useState<DGLanguageCode>('en')

  return (
    <>
      <PageContainer>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <ExtensionCard />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center">
              Welcome to Clinical Notes AI
            </Typography>
            <Typography variant="body1" textAlign="center" sx={{ mt: 1 }}>
              Choose how you would like our AI to generate notes or documents for you
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
              Session Language
            </Typography>
            <DGLanguageSelector value={language} onChange={setLanguage} />
          </Grid>
          <Grid item xs={12}>
            <DirectionButton
              title="Live Session"
              subtitle="Collect data from a live session and generate a note or document afterwards"
              onClick={navHandler(`/builder?generateType=session&language=${language}`)}
            />
          </Grid>
          <Grid item xs={12}>
            <DirectionButton
              title="Dictate Session"
              subtitle="Talk to your personal scribe about your session and generate a note or document"
              onClick={navHandler(`/builder?generateType=dictate&language=${language}`)}
            />
          </Grid>
          <Grid item xs={12}>
            <DirectionButton
              title="Document Builder"
              subtitle="View our catalog of templates and build a note or document multiple ways"
              onClick={navHandler(`/builder?language=${language}`)}
            />
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}

export default Root
