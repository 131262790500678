import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Using Zanda',
  description: 'Learn how to use the Clinical Notes AI Extension with Zanda.',
  path: 'ext-zanda',
  keywords: ['getting started', 'zanda'],
  content: md,
  videoTitle: 'How to use Clinical Notes AI with Zanda',
  video:
    'https://www.loom.com/embed/43f32435c3bd4ab899ee3f76d443fecd?sid=7e5c30c5-a68c-49df-8d67-96c514e6de41',
  linkedArticles: ['ext-template-modification'],
  icon: ExtensionIcon,
}

export default article
