import { ContextItem } from '@counsel-project/counsel-transcribe-api'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ContextDialog from './ContextDialog'
import { useCallback, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import SelectedContextItem from './SelectedContextItem'
import UploadIcon from '@mui/icons-material/UploadRounded'
import ContextUploadDialog from './ContextUploadDialog'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsRounded'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/CloseRounded'
import IconButton from '@mui/material/IconButton'
import DictationTextField from '../forms/DictationTextField'
import { usePatientNomenclature } from '../../util'
import AddIcon from '@mui/icons-material/AddRounded'

const DashedBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

type ContextSelectorProps = {
  patientId?: string
  integrationPatientId?: string
  value: ContextItem[]
  onChange: (value: ContextItem[]) => void
  observations: string
  onChangeObservations: (value: string) => void
}

const ContextSelector = ({
  value,
  onChange,
  patientId,
  integrationPatientId,
  observations,
  onChangeObservations,
}: ContextSelectorProps) => {
  const [open, setOpen] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [observationsOpen, setObservationsOpen] = useState(false)

  const handleAddContext = useCallback(
    (context: ContextItem) => {
      onChange([...value, context])
      setUploadOpen(false)
    },
    [onChange, value]
  )

  useEffect(() => {
    onChange([])
  }, [integrationPatientId, patientId, onChange])

  const patientNomenclature = usePatientNomenclature()

  return (
    <>
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Grid container>
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <EmojiObjectsIcon color="secondary" />
            </Grid>
            <Grid item xs>
              <Typography variant="body1" fontWeight={500} fontSize={18}>
                Add Your Clinical Observations and Instructions
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Click on the dictate button and tell the AI any clinical observations or instructions
              you want considered. Such as specific interventions, gender specifics, treatment
              goals, or how you want the AI to analyze or consider any chart information you’ve
              provided.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DictationTextField
              value={observations}
              onChange={onChangeObservations}
              placeholder="Enter clinical observations or any additional information..."
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={500} fontSize={18}>
              Add Chart Information
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {value.length} items selected
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpen(true)}
              disabled={!patientId && !integrationPatientId}
              startIcon={<AddIcon />}
            >
              Add Chart
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setUploadOpen(true)} startIcon={<UploadIcon />}>
              Upload a Document
            </Button>
          </Grid>
        </Grid>
        <DashedBox>
          {value.length === 0 ? (
            <>
              {!patientId ? (
                <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mt: 2 }}>
                  Select a patient to add chart context
                </Typography>
              ) : (
                <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mt: 2 }}>
                  No Chart Selected
                </Typography>
              )}
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Choose which part of the chart you would like the AI to reference before building
                your document.
              </Typography>
            </>
          ) : (
            <Grid container sx={{ p: 1 }}>
              {value.map((item, i) => (
                <Grid item xs={12} key={item.id}>
                  <SelectedContextItem
                    item={item}
                    hideTopBorder={i === 0}
                    onDelete={() => onChange(value.filter((_, index) => index !== i))}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </DashedBox>
      </Paper>
      <ContextDialog
        open={open}
        onClose={() => setOpen(false)}
        value={value}
        onChange={onChange}
        patientId={patientId}
        integrationPatientId={integrationPatientId}
      />
      <ContextUploadDialog
        patientId={patientId}
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onAddContext={handleAddContext}
      />
    </>
  )
}

export default ContextSelector
