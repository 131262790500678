import { LanguageSelector } from '@counsel-project/components'
import { LanguageCode } from '@counsel-project/counsel-generation-api'
import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import QuestionIcon from '@mui/icons-material/HelpOutlineRounded'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClosableDialog from '../../components/ClosableDialog'
import DirectionButton from '../../components/DirectionButton'
import UploadAudioTranscribeDialog from '../../components/layout/UploadAudioTranscribeDialog'
import { usePatientNomenclature } from '../../util'
import AdditionalOptionsAccordion from './AdditionalOptionsAccordion'
import OverlayLoader from './OverlayLoader'
import { createSearchParams } from './common'

type SelectGenerateTypeDialogProps = {
  open: boolean
  layout: RLayout
  viewDetailsDisabled?: boolean
  onClose: () => void
  onClickLiveSession: (language: LanguageCode) => void
  onClickDictation: (language: LanguageCode) => void
  onClickViewDetails: () => void
  onClickBuilder: (language: LanguageCode) => void
}

const SelectGenerateTypeDialog = ({
  open,
  layout,
  viewDetailsDisabled,
  onClose,
  onClickLiveSession,
  onClickDictation,
  onClickViewDetails,
  onClickBuilder,
}: SelectGenerateTypeDialogProps) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [language, setLanguage] = useState<LanguageCode>('en')
  const [uploadAudioDialogOpen, setUploadAudioDialogOpen] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  const handleFinishTranscribe = useCallback(
    (session: RPatientSession) => {
      const queryText = createSearchParams({
        layout: layout.identifier,
      })

      const url = `/builder/transcribing/${session._id}${queryText}`

      const checkLimitQuery = createSearchParams({
        cb: url,
        limit: layout.type === 'document' ? 'documents' : 'sessions',
      })

      navigate(`/check-license-limit${checkLimitQuery}`)
    },
    [layout, navigate]
  )

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Generation Type">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
              Session Language
            </Typography>
            <LanguageSelector value={language} onChange={setLanguage} />
          </Grid>
          {layout.type === 'document' && (
            <Grid item xs={12}>
              <DirectionButton
                title="Document Builder"
                subtitle="Build your document from other notes, sessions or documents."
                onClick={() => onClickBuilder(language)}
                disabled={loading}
                id="builder-button"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <DirectionButton
              title="Live Session"
              subtitle={`Start a live session with your ${patientNomenclature} and let Clinical Notes AI
              listen to the conversation`}
              onClick={() => onClickLiveSession(language)}
              disabled={loading}
              id="live-session-button"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DirectionButton
              title="Dictation"
              subtitle="Dictate the details required to generate this documentation"
              onClick={() => onClickDictation(language)}
              disabled={loading}
              id="dictation-button"
              fullWidth
            />
          </Grid>
          {layout.type === 'note' && (
            <Grid item xs={12}>
              <DirectionButton
                title="Note Builder"
                subtitle="Build your note from other sessions or documents."
                onClick={() => onClickBuilder(language)}
                disabled={loading}
                id="note-builder-button"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <DirectionButton
              title="Upload Audio"
              subtitle="Upload an audio file to build a note or document"
              onClick={() => setUploadAudioDialogOpen(true)}
              disabled={loading}
              id="upload-audio-button"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              startIcon={<QuestionIcon />}
              onClick={onClickViewDetails}
              disabled={viewDetailsDisabled}
            >
              View Example
            </Button>
          </Grid>
          <Grid item xs={12}>
            <AdditionalOptionsAccordion language={language} layout={layout} />
          </Grid>
        </Grid>
      </DialogContent>
      <UploadAudioTranscribeDialog
        open={uploadAudioDialogOpen}
        onClose={() => setUploadAudioDialogOpen(false)}
        onFinishTranscribe={handleFinishTranscribe}
        loading={loading}
        setLoading={setLoading}
      />
      {loading && <OverlayLoader />}
    </ClosableDialog>
  )
}

export default SelectGenerateTypeDialog
