import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import NotFoundPage from './routes/404'
import Admin from './routes/admin/index'
import Licenses from './routes/admin/licenses'
import AdminOrganizations from './routes/admin/organizations'
import AdminSessions from './routes/admin/sessions'
import BuilderPage from './routes/builder'
import BuilderGeneratingPage from './routes/builder/generating'
import BuilderLayoutPage from './routes/builder/layout/[id]'
import BuilderRegeneratePage from './routes/builder/regenerate/[sessionId]'
import BuilderTranscribeErroredPage from './routes/builder/transcribe-errored/[id]'
import BuilderTranscribePage from './routes/builder/transcribe'
import BuilderTranscribingPage from './routes/builder/transcribing/[id]'
import ChangelogPage from './routes/changelog'
import ClientId from './routes/client/[id]'
import DLCA2pPolicyPage from './routes/dlc-a2p-policy'
import Login from './routes/login'
import MSLoginPage from './routes/mslogin'
import NavLayout from './routes/NavLayout'
import Onboarding from './routes/onboarding'
import OrganizationId from './routes/organization/[id]'
import Organization from './routes/organization/index'
import Organizations from './routes/organizations/index'
import OrganizationsNew from './routes/organizations/new'
import Pricing from './routes/pricing'
import PricingSelectPage from './routes/pricing-select'
import PrivacyPolicy from './routes/privacy-policy'
import Profile from './routes/profile'
import PurchasePage from './routes/purchase/index'
import PurchaseSuccessPage from './routes/purchase/success'
import RedirectToBuilderPage from './routes/RedirectToBuilderPage'
import Register from './routes/register'
import ResetPassword from './routes/reset-password'
import Root from './routes/Root'
import SessionsId from './routes/sessions/[id]'
import Sessions from './routes/sessions/index'
import SubscriptionsPage from './routes/subscriptions'
import SubscriptionPage from './routes/subscriptions/[id]'
import Referral from './routes/referral'
import TermsOfService from './routes/terms-of-service'
import TestPage from './routes/test-page'
import { onBeforeUnload } from './util/onBeforeLoad'
import theme from './util/theme'
import CheckLicenseLimitPage from './routes/check-license-limit'
import HelpPage from './routes/help'
import LayoutsPage from './routes/layouts'
import NewLayoutPage from './routes/layouts/new'
import ViewLayoutPage from './routes/layouts/view/[layoutId]'
import ErrorPage from './routes/Error'
import TestErrorPage from './routes/test-error'
import BuilderGroupLayoutPage from './routes/builder/group-layout/[id]'
import MultipleSessionsPage from './routes/sessions/multiple'
import InvitationsPage from './routes/invitations'
import InvitationPage from './routes/invitations/[id]'
import AdminLayoutsPage from './routes/layouts/admin'
import ViewAdminLayoutPage from './routes/layouts/admin/view/[layoutId]'
import AutoLogin from './routes/auto-login'
import AdminNotes from './routes/admin/notes'
import './util/tracking/posthog'
import UpdateSubscriptionPage from './routes/subscriptions/update/[subscriptionId]'
import AdminTranscriptsPage from './routes/admin/transcripts'
import AdminUsersPage from './routes/admin/users'
import AppleLoginPage from './routes/applelogin'
import LayoutFormPage from './routes/layouts/forms/[layoutId]'
import BuilderTranscribeNewPage from './routes/builder/transcribe-new'
import StatsPage from './routes/stats'
import TranscriptLogsPage from './routes/admin/transcriptlogs'
import ModelsPage from './routes/admin/models'
import BAA from './routes/baa'

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: <NavLayout />,
    children: [
      {
        path: '/baa',
        element: <BAA />,
      },
      {
        path: '/stats',
        element: <StatsPage />,
      },
      {
        path: '/applelogin',
        element: <AppleLoginPage />,
      },
      {
        path: '/admin/models',
        element: <ModelsPage />,
      },
      {
        path: '/admin/users',
        element: <AdminUsersPage />,
      },
      {
        path: '/admin/transcripts',
        element: <AdminTranscriptsPage />,
      },
      {
        path: '/admin/transcriptlogs',
        element: <TranscriptLogsPage />,
      },
      {
        path: '/auto-login',
        element: <AutoLogin />,
      },
      {
        path: '/test-error',
        element: <TestErrorPage />,
      },
      {
        path: '/',
        element: <Root />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/referral',
        element: <Referral />,
      },
      {
        path: '/help',
        element: <HelpPage />,
      },
      {
        path: '/pricing',
        element: <Pricing />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/10dlc-a2p-policy',
        element: <DLCA2pPolicyPage />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/changelog',
        element: <ChangelogPage />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/admin/notes',
        element: <AdminNotes />,
      },
      {
        path: '/admin/licenses',
        element: <Licenses />,
      },
      {
        path: '/admin/organizations',
        element: <AdminOrganizations />,
      },
      {
        path: '/organization',
        element: <Organization />,
      },
      {
        path: '/organization/:id',
        element: <OrganizationId />,
      },
      {
        path: '/organizations',
        element: <Organizations />,
      },
      {
        path: '/organizations/new',
        element: <OrganizationsNew />,
      },
      {
        path: '/sessions',
        element: <Sessions />,
      },
      {
        path: '/sessions/multiple',
        element: <MultipleSessionsPage />,
      },
      {
        path: '/sessions/:id',
        element: <SessionsId />,
      },
      {
        path: '/client/:id',
        element: <ClientId />,
      },
      {
        path: '/onboarding',
        element: <Onboarding />,
      },
      {
        path: '/reset-password',
        element: <ResetPassword />,
      },
      {
        path: '/admin/sessions',
        element: <AdminSessions />,
      },
      {
        path: '/purchase',
        element: <PurchasePage />,
      },
      {
        path: '/purchase/success',
        element: <PurchaseSuccessPage />,
      },
      {
        path: '/subscriptions',
        element: <SubscriptionsPage />,
      },
      {
        path: '/subscriptions/:id',
        element: <SubscriptionPage />,
      },
      {
        path: '/subscriptions/update/:subscriptionId',
        element: <UpdateSubscriptionPage />,
      },
      {
        path: '/pricing-select',
        element: <PricingSelectPage />,
      },
      {
        path: '/notes',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/old',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/transcribe/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/transcribing/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/generating/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/generate/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/generated/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/transcribe-errored/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/notes/generate-errored/:id',
        element: <RedirectToBuilderPage />,
      },
      {
        path: '/mslogin',
        element: <MSLoginPage />,
      },
      {
        path: '/test',
        element: <TestPage />,
      },
      {
        path: '/builder',
        element: <BuilderPage />,
      },
      {
        path: '/builder/transcribe',
        element: <BuilderTranscribePage />,
      },
      {
        path: '/builder/transcribe-new',
        element: <BuilderTranscribeNewPage />,
      },
      {
        path: '/builder/transcribing/:id',
        element: <BuilderTranscribingPage />,
      },
      {
        path: '/builder/transcribe-errored/:id',
        element: <BuilderTranscribeErroredPage />,
      },
      {
        path: '/builder/generating',
        element: <BuilderGeneratingPage />,
      },
      {
        path: '/builder/layout/:id',
        element: <BuilderLayoutPage />,
      },
      {
        path: '/builder/group-layout/:id',
        element: <BuilderGroupLayoutPage />,
      },
      {
        path: '/builder/regenerate/:sessionId',
        element: <BuilderRegeneratePage />,
      },
      {
        path: '/check-license-limit',
        element: <CheckLicenseLimitPage />,
      },
      {
        path: '/layouts',
        element: <LayoutsPage />,
      },
      {
        path: '/layouts/admin',
        element: <AdminLayoutsPage />,
      },
      {
        path: '/layouts/forms/:layoutId',
        element: <LayoutFormPage />,
      },
      {
        path: '/layouts/admin/view/:layoutId',
        element: <ViewAdminLayoutPage />,
      },
      {
        path: '/layouts/new',
        element: <NewLayoutPage />,
      },
      {
        path: '/layouts/view/:layoutId',
        element: <ViewLayoutPage />,
      },
      {
        path: '/invitations',
        element: <InvitationsPage />,
      },
      {
        path: '/invitations/:id',
        element: <InvitationPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ].map((p) => ({
      ...p,
      errorElement: <ErrorPage />,
    })),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)

window.onbeforeunload = onBeforeUnload

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
