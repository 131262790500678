import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - Getting Started',
  description:
    'Learn how to use the EHR Extension to easily integrate with your electronic health record system in Clinical Notes AI.',
  path: 'getting-started-extension',
  keywords: ['ehr extension', 'getting started', 'integration'],
  content: md,
  videoTitle: 'Extension Walkthrough',
  video:
    'https://www.loom.com/embed/53296f38301f4d79b3c35beb5092ac43?sid=b52524ee-e848-494e-ad13-5a91b3e80aac',
  linkedArticles: ['ext-edit-with-ai'],
  icon: ExtensionIcon,
}

export default article
