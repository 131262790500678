import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { removeMarkdown } from '../../util/copy'

type ContextTooltipProps = {
  content: string
} & Omit<TooltipProps, 'title' | 'content'>

const ContextTooltip = ({ content, ...props }: ContextTooltipProps) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'none',
            width: '300px',
            fontSize: '18px',
            padding: '8px 12px',
            backgroundColor: 'white',
            color: 'black',
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
          },
        },
      }}
      title={
        <>
          {removeMarkdown(content)
            ?.split('\n')
            .slice(0, 10)
            .map((line) => (
              <Typography key={line} variant="body2" sx={{ mb: 0.5 }}>
                {line}
              </Typography>
            ))}
          {content?.split('\n').length > 10 && (
            <Typography variant="body2">
              ...{content?.split('\n').length - 10} more lines
            </Typography>
          )}
        </>
      }
      placement="left"
      arrow
      {...props}
    />
  )
}

export default ContextTooltip
