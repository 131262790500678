import { ContextItem } from '@counsel-project/counsel-transcribe-api'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewIcon from '@mui/icons-material/Visibility'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ContextTooltip from './ContextTooltip'

type SelectedContextItemProps = {
  item: ContextItem
  hideTopBorder?: boolean
  onDelete?: () => void
}

const SelectedContextItem = ({ item, hideTopBorder, onDelete }: SelectedContextItemProps) => {
  return (
    <Box sx={{ cursor: 'pointer', borderTop: hideTopBorder ? 'none' : '1px solid #e0e0e0', mb: 1 }}>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs>
          <Typography noWrap>{item.title}</Typography>
          <Typography color="text.secondary">{item.date}</Typography>
        </Grid>
        <Grid item>
          <ContextTooltip content={item.content}>
            <IconButton>
              <ViewIcon />
            </IconButton>
          </ContextTooltip>
        </Grid>
        {onDelete && (
          <Grid item>
            <IconButton onClick={onDelete} sx={{ ml: 1 }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default SelectedContextItem
