import { ArticleItem } from '../types'
import md from './index.md'
import ExtensionIcon from '@mui/icons-material/ExtensionRounded'

const article: ArticleItem = {
  title: 'Extension - How to use Edit with AI',
  description:
    'Learn how to use the Clinical Notes AI Extension to easily edit notes & documents from your sessions.',
  path: 'ext-edit-with-ai',
  keywords: ['edit with ai'],
  content: md,
  videoTitle: 'How to use Edit with AI',
  video:
    'https://www.loom.com/embed/6010392e33d34b70b513bc7e249a0950?sid=36a6e077-039d-41b1-9092-c8ec9229b65c',
  linkedArticles: ['ext-template-modification'],
  icon: ExtensionIcon,
}

export default article
