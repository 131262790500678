import Grid from '@mui/material/Grid'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Breadcrumbs, { BreadcrumbPath } from '../../components/Breadcrumbs'
import PageContainer from '../../components/layout/PageContainer'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import exampleLayouts from './example-layouts'
import LayoutCaptureInstructions from './LayoutCaptureInstructions'
import LayoutControls, { LayoutSaveOptions } from './LayoutControls'
import LayoutEditor from './LayoutEditor'
import LayoutInfoEditor from './LayoutInfoEditor'
import { logCreateTemplate } from '../../util/tracking'

const NewLayoutPage = () => {
  const [searchParams] = useSearchParams()

  const exampleId = searchParams.get('identifier')
  const admin = searchParams.get('admin') === 'true'

  const navigate = useNavigate()

  const example = exampleLayouts.find((layout) => layout.identifier === exampleId)

  const defaultValue = useMemo(() => example?.template ?? '', [example])

  const [, , populateLayouts] = useLayouts()

  const [value, setValue] = useState(defaultValue)
  const [info, setInfo] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [dictationDescription, setDictationDescription] = useState('')
  const [autoGenerateDictation, setAutoGenerateDictation] = useState(true)
  const [sessionDescription, setSessionDescription] = useState('')
  const [autoGenerateSession, setAutoGenerateSession] = useState(true)
  const [saving, setSaving] = useState(false)

  const handleSave = useCallback(
    async (options: LayoutSaveOptions) => {
      try {
        setIsLoading(true)

        await checkToken()

        const res = await transcribeRequest.layouts.create({
          token: '',
          ...options,
          maxDocuments: 10,
          maxNotes: 10,
          maxObservations: 10,
          tags: ['Custom'],
          allowDictation: true,
          allowTranscript: true,
          priority: 999,
          type: 'document',
          includeDictations: true,
          includeNotes: true,
          includeTranscripts: false,
          transferTranscripts: false,
          requiredLayouts: [],
          template: value,
          professions: [],
          dictationDescription: autoGenerateDictation ? undefined : dictationDescription,
          sessionDescription: autoGenerateSession ? undefined : sessionDescription,
          documentInfo: info || undefined,
        })

        logCreateTemplate({
          id: res.result._id,
          name: res.result.name,
        })

        try {
          await transcribeRequest.layouts.setOptions({
            token: '',
            layoutId: res.result._id,
            options: [
              {
                type: 'checkbox',
                categoryLabel: 'Omit Names',
                allowMultiple: false,
                required: false,
                separator: ' ',
                options: [
                  {
                    id: 'omit-names-custom-true',
                    label: 'Omit Names',
                    secondPass: `Replace all names of the client with 'client' or 'The client'. Anonymize all other names too. Keep referring to the client by their original pronouns (she/her, he/him or they/them depending on how the original document refers to the client). Keep the markdown formatting of the original document.`,
                    secondPassModel: 6,
                  },
                ],
              },
            ],
          })
        } catch (err) {
          log.error(err)
        }

        toast.success('Layout created successfully')
        populateLayouts(true)
        if (admin) {
          navigate(`/layouts/admin/view/${res.result._id}`)
        } else {
          navigate(`/layouts/view/${res.result._id}`)
        }
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [
      value,
      navigate,
      populateLayouts,
      dictationDescription,
      autoGenerateDictation,
      sessionDescription,
      autoGenerateSession,
      info,
      admin,
    ]
  )

  const breadcrumbs: BreadcrumbPath[] = [
    {
      name: 'Templates',
      path: '/layouts',
    },
    {
      name: 'New',
    },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LayoutInfoEditor value={info} onChange={setInfo} disabled={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <LayoutEditor
            id="layout-editor-new"
            defaultValue={defaultValue}
            onChange={(text) => setValue(text)}
            onSaving={setSaving}
          />
        </Grid>
        <Grid item xs={12}>
          <LayoutCaptureInstructions
            id="layout-editor-dictation-instructions"
            title="Dictation Capture Instructions"
            placeholder="Enter dictation instructions here..."
            defaultValue={dictationDescription}
            onChange={setDictationDescription}
            onSaving={setSaving}
            onToggleGenerate={() => setAutoGenerateDictation((prev) => !prev)}
            autoGenerate={autoGenerateDictation}
          />
        </Grid>
        <Grid item xs={12}>
          <LayoutCaptureInstructions
            id="layout-editor-session-instructions"
            title="Live Session Instructions"
            placeholder="Enter live session instructions here..."
            defaultValue={sessionDescription}
            onChange={setSessionDescription}
            onSaving={setSaving}
            onToggleGenerate={() => setAutoGenerateSession((prev) => !prev)}
            autoGenerate={autoGenerateSession}
          />
        </Grid>
        <Grid item xs={12}>
          <LayoutControls loading={isLoading || saving} onSave={handleSave} />
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default NewLayoutPage
