import DialogContent from '@mui/material/DialogContent'
import ClosableDialog from '../ClosableDialog'
import FileUpload from '../forms/FileUpload'
import { useCallback, useEffect, useState } from 'react'
import LabeledTextField from '../layout/LabeledTextField'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import checkToken from '../../util/auth/checkToken'
import { visionRequest } from '../../util/api/vision-api'
import toast from 'react-hot-toast'
import { visionSessionsPromise } from '../../util/processPromise'
import { useMountedState } from 'react-use'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { ContextItem } from '@counsel-project/counsel-transcribe-api'
import handleError from '../../util/handleError'

type ContextOptionsProps = {
  patientId?: string
  open: boolean
  onClose: () => void
  onAddContext: (context: ContextItem) => void
}

const ContextUploadDialog = ({ patientId, open, onClose, onAddContext }: ContextOptionsProps) => {
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<'uploading' | 'processing' | 'success' | 'error'>('uploading')

  const isMounted = useMountedState()

  const handleUpload = useCallback(
    async (newFile: File) => {
      try {
        if (!newFile) return

        setFile(newFile)

        setLoading(true)

        await checkToken()

        const formData = new FormData()
        formData.append('file', newFile)

        const { processId } = await visionRequest.sessions.generate({
          token: '',
          formData,
          patientId: patientId || undefined,
        })

        setState('processing')

        toast.loading('Processing document...', { id: 'process-document', duration: 999999 })

        const sessionId = await visionSessionsPromise({
          processId,
        })

        setState('success')

        toast.success('Document processed', { id: 'process-document', duration: 5000 })

        if (!isMounted()) return

        try {
          const { result } = await transcribeRequest.sessions.get({
            token: '',
            sessionId,
          })

          onAddContext({
            id: result._id,
            title: result.summary || '',
            content: result.note || '',
            date: new Date().toISOString(),
            type: 'assessment',
            ehr: false,
          })
        } catch (err) {
          handleError(err)
        }
      } catch (err) {
        handleError(err)
        toast.error('Error processing document. Please try again.', {
          id: 'process-document',
          duration: 5000,
        })
      } finally {
        setLoading(false)
      }
    },
    [onAddContext, patientId, isMounted]
  )

  useEffect(() => {
    if (state === 'success') {
      onClose()
    }
  }, [state, onClose])

  useEffect(() => {
    if (!open) {
      setFile(null)
      setState('uploading')
    }
  }, [open])

  return (
    <ClosableDialog titleText="Upload a Document" open={open} onClose={onClose}>
      <DialogContent>
        {!loading ? (
          <>
            <FileUpload
              disabled={loading}
              title="Upload a file"
              subtitle="Drag and drop a file here, or click to select a file to upload."
              accept=".pdf,.docx"
              onFileChange={handleUpload}
              file={file}
            />
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="body1"
              textAlign="center"
              fontWeight={500}
              fontSize={18}
              sx={{ mb: 1 }}
            >
              Uploading...
            </Typography>
            <LinearProgress />
          </Box>
        )}
      </DialogContent>
    </ClosableDialog>
  )
}

export default ContextUploadDialog
