import { RDefaultPrompt } from '@counsel-project/counsel-transcribe-api/dist/common/database/RDefaultPrompt'
import EditIcon from '@mui/icons-material/Edit'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

type DefaultPromptProps = {
  value: RDefaultPrompt
  onEdit: (value: RDefaultPrompt) => void
}

const DefaultPrompt = ({ value, onEdit }: DefaultPromptProps) => {
  return (
    <Paper elevation={2} sx={{ p: 2, display: 'flex' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6" sx={{ mr: 2, mt: 0.5 }}>
            {value.nameContains || value.nameEquals}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary" component="span">
            {value.fieldPrompts.length} Field Prompts
          </Typography>
        </Grid>
        <Grid item>
          <Chip
            label={value.nameContains ? 'Contains' : 'Equals'}
            color={value.nameContains ? 'primary' : 'secondary'}
          />
        </Grid>
        <Grid item>
          <Chip
            label={value.caseSensitive ? 'Case Sensitive' : 'Case Insensitive'}
            color={value.caseSensitive ? 'success' : 'error'}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={() => onEdit(value)}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DefaultPrompt
