import {
  RDefaultPrompt,
  TemplatePromptField,
} from '@counsel-project/counsel-transcribe-api/dist/common/database/RDefaultPrompt'
import ClosableDialog from '../../components/ClosableDialog'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useEffect, useMemo, useState } from 'react'
import Radio from '@mui/material/Radio'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import LayoutProfessionSelector from '../layouts/LayoutProfessionsSelector'
import log from '../../util/logging'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { FieldType } from '@counsel-project/counsel-generation-api'
import FieldTypeSelector from './FieldTypeSelector'
import ShowIcon from '@mui/icons-material/Visibility'
import HideIcon from '@mui/icons-material/VisibilityOff'

type FieldPromptWithID = TemplatePromptField & { id: string }

type FieldPromptProps = {
  fieldPrompt: FieldPromptWithID
  onChange: (fieldPrompt: FieldPromptWithID) => void
  onDelete: (fieldPrompt: FieldPromptWithID) => void
}

const FieldPrompt = ({ fieldPrompt, onChange, onDelete }: FieldPromptProps) => {
  const stagedName = useMemo(
    () => fieldPrompt.nameContains || fieldPrompt.nameEquals || '',
    [fieldPrompt]
  )
  const stagedMode = useMemo(
    () => (fieldPrompt.nameContains ? 'contains' : 'equals'),
    [fieldPrompt]
  )
  const stagedCaseSensitive = useMemo(() => fieldPrompt.caseSensitive || false, [fieldPrompt])
  const stagedPrompt = useMemo(() => fieldPrompt.prompt || '', [fieldPrompt])
  const stagedTypes = useMemo(() => fieldPrompt.types || [], [fieldPrompt])
  const [hidden, setHidden] = useState(true)

  const handleChangeName = (name: string) => {
    onChange({
      ...fieldPrompt,
      nameContains: stagedMode === 'contains' ? name : undefined,
      nameEquals: stagedMode === 'equals' ? name : undefined,
    })
  }

  const handleChangeMode = (mode: 'contains' | 'equals') => {
    onChange({
      ...fieldPrompt,
      nameContains: mode === 'contains' ? stagedName : undefined,
      nameEquals: mode === 'equals' ? stagedName : undefined,
    })
  }

  const handleChangeCaseSensitive = (caseSensitive: boolean) => {
    onChange({ ...fieldPrompt, caseSensitive })
  }

  const handleChangePrompt = (prompt: string) => {
    onChange({ ...fieldPrompt, prompt })
  }

  const handleChangeTypes = (types: FieldType[]) => {
    onChange({ ...fieldPrompt, types })
  }

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="Field Name Matcher"
            value={stagedName}
            onChange={(e) => handleChangeName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <RadioGroup
            value={stagedMode}
            onChange={(e) => handleChangeMode(e.target.value as 'contains' | 'equals')}
            sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
          >
            <FormControlLabel value="contains" control={<Radio />} label="Contains" />
            <FormControlLabel value="equals" control={<Radio />} label="Equals" />
          </RadioGroup>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={stagedCaseSensitive}
                onChange={(e) => handleChangeCaseSensitive(e.target.checked)}
              />
            }
            label="Case Sensitive"
          />
        </Grid>
        <Grid item>
          <IconButton onClick={() => onDelete(fieldPrompt)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setHidden(!hidden)} color="primary">
            {hidden ? <HideIcon /> : <ShowIcon />}
          </IconButton>
        </Grid>
        {!hidden ? (
          <>
            <Grid item xs={12}>
              <TextField
                label="Prompt"
                value={stagedPrompt}
                onChange={(e) => handleChangePrompt(e.target.value)}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldTypeSelector value={stagedTypes} onChange={handleChangeTypes} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                {stagedPrompt}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

type DefaultPromptDialogProps = {
  open: boolean
  onClose: () => void
  prompt?: RDefaultPrompt | null
  onChange: (prompt: RDefaultPrompt) => void
  onDelete: (prompt: RDefaultPrompt) => void
}

const DefaultPromptDialog = ({
  open,
  onClose,
  prompt,
  onChange,
  onDelete,
}: DefaultPromptDialogProps) => {
  const [stagedName, setStagedName] = useState(prompt?.nameContains || prompt?.nameEquals || '')
  const [stagedMode, setStagedMode] = useState<'contains' | 'equals'>(
    prompt?.nameContains ? 'contains' : 'equals'
  )
  const [stagedCaseSensitive, setStagedCaseSensitive] = useState(prompt?.caseSensitive || false)
  const [stagedFieldPrompts, setStagedFieldPrompts] = useState<FieldPromptWithID[]>(
    (prompt?.fieldPrompts || []).map((fp) => ({ ...fp, id: Math.random().toString() }))
  )
  const [professions, setProfessions] = useState<string[]>(prompt?.professions || ['therapist'])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (prompt) {
      setStagedName(prompt.nameContains || prompt.nameEquals || '')
      setStagedMode(prompt.nameContains ? 'contains' : 'equals')
      setStagedCaseSensitive(prompt.caseSensitive || false)
      setStagedFieldPrompts(
        prompt.fieldPrompts.map((fp) => ({ ...fp, id: Math.random().toString() }))
      )
      setProfessions(prompt.professions || ['therapist'])
    } else {
      // set to defaults
      setStagedName('')
      setStagedMode('contains')
      setStagedCaseSensitive(false)
      setStagedFieldPrompts([])
      setProfessions(['therapist'])
    }
  }, [prompt])

  const handleAddFieldPrompt = () => {
    setStagedFieldPrompts([
      ...stagedFieldPrompts,
      {
        nameContains: '',
        caseSensitive: false,
        prompt: '',
        types: ['string'],
        id: Math.random().toString(),
      },
    ])
  }

  const handleDeleteFieldPrompt = (fieldPrompt: FieldPromptWithID) => {
    setStagedFieldPrompts((prev) => prev.filter((fp) => fp.id !== fieldPrompt.id))
  }

  const handleChangeFieldPrompt = (fieldPrompt: FieldPromptWithID) => {
    setStagedFieldPrompts((prev) => prev.map((fp) => (fp.id === fieldPrompt.id ? fieldPrompt : fp)))
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)

      console.log(stagedFieldPrompts)
      if (prompt) {
        const { result } = await transcribeRequest.prompts.update({
          token: '',
          promptId: prompt._id,
          nameContains: stagedMode === 'contains' ? stagedName : undefined,
          nameEquals: stagedMode === 'equals' ? stagedName : undefined,
          caseSensitive: stagedCaseSensitive,
          fieldPrompts: stagedFieldPrompts.map((fp) => ({
            ...fp,
            id: undefined,
          })),
          professions,
        })

        onChange(result)
      } else {
        const { result } = await transcribeRequest.prompts.create({
          token: '',
          nameContains: stagedMode === 'contains' ? stagedName : undefined,
          nameEquals: stagedMode === 'equals' ? stagedName : undefined,
          caseSensitive: stagedCaseSensitive,
          fieldPrompts: stagedFieldPrompts.map((fp) => ({
            ...fp,
            id: undefined,
          })),
          professions,
        })

        onChange(result)
      }

      onClose()
    } catch (err) {
      log.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      if (!prompt) return
      setIsLoading(true)
      await transcribeRequest.prompts.delete({ token: '', promptId: prompt._id })
      onDelete(prompt)
    } catch (err) {
      log.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText={prompt ? 'Edit Prompt' : 'Create Prompt'}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h6">{stagedFieldPrompts.length} Field Prompts</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              label="Template Name Matcher"
              value={stagedName}
              onChange={(e) => setStagedName(e.target.value)}
              fullWidth
              placeholder="eg. DAP, SOAP, etc."
            />
          </Grid>
          <Grid item>
            <RadioGroup
              value={stagedMode}
              onChange={(e) => setStagedMode(e.target.value as 'contains' | 'equals')}
              sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
            >
              <FormControlLabel value="contains" control={<Radio />} label="Contains" />
              <FormControlLabel value="equals" control={<Radio />} label="Equals" />
            </RadioGroup>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={stagedCaseSensitive}
                  onChange={(e) => setStagedCaseSensitive(e.target.checked)}
                />
              }
              label="Case Sensitive"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs>
            <LayoutProfessionSelector value={professions} onChange={setProfessions} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {stagedFieldPrompts.map((fieldPrompt) => (
            <Grid item xs={12} key={fieldPrompt.id}>
              <FieldPrompt
                fieldPrompt={fieldPrompt}
                onChange={handleChangeFieldPrompt}
                onDelete={handleDeleteFieldPrompt}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} justifyContent="end">
          <Grid item>
            <Button onClick={handleAddFieldPrompt}>Add Field Prompt</Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {prompt && (
          <Button onClick={handleDelete} disabled={isLoading}>
            Delete
          </Button>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default DefaultPromptDialog
